import { __decorate, __extends } from "tslib";
import React from 'react';
import classnames from 'classnames';
import { VODCSSModules } from '../../../../../shared/utils/wix-connect';
import PropTypes from 'prop-types';
import Icon from '../../../../../shared/components/icon/icon';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './buttons.styl';
var CarouselButton = /** @class */ (function (_super) {
    __extends(CarouselButton, _super);
    function CarouselButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function (event) {
            event.preventDefault();
            _this.props.onClick(event);
        };
        return _this;
    }
    CarouselButton.prototype.render = function () {
        var _a = this.props, direction = _a.direction, isActive = _a.isActive;
        var styleNames = classnames('button-container', direction, {
            inactive: isActive === false,
        });
        return (React.createElement("div", { styleName: styleNames, onClick: this.handleClick },
            React.createElement("div", { styleName: "button" },
                React.createElement(Icon, { styleName: "triangle-" + direction, name: "triangle-right" }))));
    };
    CarouselButton.propTypes = {
        direction: PropTypes.string,
        isActive: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    };
    CarouselButton.defaultProps = {
        direction: 'left',
        isActive: true,
    };
    CarouselButton = __decorate([
        withStyles(styles),
        VODCSSModules(styles)
    ], CarouselButton);
    return CarouselButton;
}(React.Component));
export default CarouselButton;
