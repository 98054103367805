import { __assign, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
var isActive = function (spec) {
    var index;
    if (spec.rtl) {
        index = spec.slideCount - 1 - spec.index;
    }
    else {
        index = spec.index;
    }
    var centerOffset;
    var slickActive;
    if (spec.centerMode) {
        centerOffset = Math.floor(spec.slidesToShow / 2);
        if (index > spec.currentSlide - centerOffset - 1 &&
            index <= spec.currentSlide + centerOffset) {
            slickActive = true;
        }
    }
    else {
        slickActive =
            spec.currentSlide <= index &&
                index < spec.currentSlide + spec.slidesToShow;
    }
    return slickActive;
};
var getSlideStyle = function (spec) {
    var style = {
        width: spec.slideWidth,
        flexShrink: 0,
    };
    return style;
};
var getKey = function (child, fallbackKey) {
    // key could be a zero
    return child.key === null || _.isUndefined(child.key) ? fallbackKey : child.key;
};
var renderSlides = function (spec) {
    var slides = [];
    var preCloneSlides = [];
    var postCloneSlides = [];
    React.Children.forEach(spec.children, function (child, index) {
        var childStyle = getSlideStyle(_.assign({}, spec, { index: index }));
        var isActiveSlide = isActive(__assign({ index: index }, spec));
        slides.push(React.cloneElement(child, __assign(__assign({ key: "original" + getKey(child, index), 'data-index': index }, (isActiveSlide && { 'data-active': 'true' })), { className: child.props.className, style: _.assign({}, child.props.style || {}, childStyle) })));
    });
    return preCloneSlides.concat(slides, postCloneSlides);
};
var Track = /** @class */ (function (_super) {
    __extends(Track, _super);
    function Track() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Track.prototype.render = function () {
        var slides = renderSlides(this.props);
        var _a = this.props, trackStyle = _a.trackStyle, getRef = _a.getRef, className = _a.className;
        return (React.createElement("div", { ref: getRef, className: className, style: __assign({ display: 'flex' }, trackStyle) }, slides));
    };
    Track.propTypes = {
        trackStyle: PropTypes.object,
        getRef: PropTypes.func,
        className: PropTypes.string,
    };
    Track.defaultProps = {
        getRef: _.noop,
    };
    return Track;
}(React.Component));
export default Track;
