var _a;
import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import ShareButtons from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/share-buttons/share-buttons';
import { SHARE_MODES } from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/share-buttons/constants';
import ClosableOverlay from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/closable-overlay/closable-overlay';
import Animate from '@wix/wix-vod-shared/dist/src/widget/ui-components/animate/animate';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import socialNetworks from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';
import { getShareMode, isShareOverlayOpen, getInstanceInfo, getVideoUrl, getChannelUrl, } from '../../selectors/share-overlay';
import { canShowChannelShare, isRTL, } from '../../../shared/selectors/app-settings';
import { getCompId, getCurrentPageId, getBiToken, } from '../../redux/client/hydrated-data/hydrated-data';
import * as viewModeSelectors from '../../selectors/view-mode';
import { canShareVideo } from '../../../shared/selectors/video-share';
import { isEmbedEnabled } from '../../selectors/embed';
import { closeShareOverlay } from '../../redux/client/actions/share-overlay';
import { notForPreview } from '../../../shared/utils/not-for-preview';
import { logBi } from '../../../shared/worker/actions/bi';
import { reportStats } from '../../redux/server/actions/stats/report';
import STATS_EVENTS from '../../../shared/constants/stats/events';
import EmbedForm from './embed-form/embed-form';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './share-overlay.styl';
var TRANSLATIONS_MODE_MAP = (_a = {},
    _a[SHARE_MODES.VIDEO] = 'share-overlay.social.share',
    _a[SHARE_MODES.CHANNEL] = 'widget.compact-view.components.action-bar.share-this-channel',
    _a.CHANNEL_AND_VIDEO = 'share-overlay.social.title',
    _a);
var SHARE_EMBED_ENUM = 'embed';
var ShareOverlay = /** @class */ (function (_super) {
    __extends(ShareOverlay, _super);
    function ShareOverlay(props) {
        var _this = _super.call(this, props) || this;
        _this.openEmbedForm = notForPreview(function (_a) {
            var shareType = _a.shareType;
            _this.reportStats({
                networkName: SHARE_EMBED_ENUM,
                shareType: shareType,
            });
            _this.setState({
                isEmbedOpen: true,
            });
        });
        _this.closeEmbedForm = function () {
            if (!_this.state.isEmbedOpen) {
                return;
            }
            _this.setState({
                isEmbedOpen: false,
            });
        };
        _this.closeShareOverlay = function () {
            _this.props.closeShareOverlay();
            _this.props.onClosed();
        };
        _this.saveRef = function (ref) {
            _this.containerRef = ref;
        };
        _this.handleShare = notForPreview(function (_a) {
            var networkName = _a.networkName, shareType = _a.shareType;
            var _b = _this.props, channelData = _b.channelData, videoItem = _b.videoItem;
            _this.reportStats({ networkName: networkName, shareType: shareType });
            _this.props.logBi('widget.vid.share.clicked', {
                button: networkName,
                shareType: shareType,
                channelID: channelData.id,
                videoID: videoItem.id,
            });
        });
        _this.handleLinkCopied = notForPreview(function (_a) {
            var shareType = _a.shareType;
            var _b = _this.props, channelData = _b.channelData, videoItem = _b.videoItem;
            _this.reportStats({
                networkName: socialNetworks.LINK,
                shareType: shareType,
            });
            _this.props.logBi('widget.vid.share.clicked', {
                button: 'link',
                shareType: shareType,
                channelID: channelData.id,
                videoID: videoItem ? videoItem.id : null,
            });
        });
        _this.state = {
            isEmbedOpen: false,
        };
        return _this;
    }
    ShareOverlay.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var _a = this.props, isShareOverlayOpen = _a.isShareOverlayOpen, isEditor = _a.isEditor;
        // will be closed
        if (isShareOverlayOpen && !nextProps.isShareOverlayOpen) {
            this.closeEmbedForm();
        }
        // back to editor from preview
        if (!isEditor && nextProps.isEditor) {
            this.closeShareOverlay();
        }
    };
    ShareOverlay.prototype.componentDidUpdate = function (prevProps) {
        var isShareOverlayOpen = this.props.isShareOverlayOpen;
        // is opened
        if (!prevProps.isShareOverlayOpen && isShareOverlayOpen) {
            focus(this.containerRef);
        }
    };
    ShareOverlay.prototype.componentWillUnmount = function () {
        var _a = this.props, isShareOverlayOpen = _a.isShareOverlayOpen, closeShareOverlay = _a.closeShareOverlay;
        if (isShareOverlayOpen) {
            closeShareOverlay();
        }
    };
    ShareOverlay.prototype.getTitleByShareMode = function (shareMode) {
        var _a = this.props, isChannelShareVisible = _a.isChannelShareVisible, isVideoShareVisible = _a.isVideoShareVisible;
        if (isChannelShareVisible && isVideoShareVisible) {
            return i18n.t(TRANSLATIONS_MODE_MAP.CHANNEL_AND_VIDEO);
        }
        var translation = TRANSLATIONS_MODE_MAP[shareMode];
        return translation ? i18n.t(translation) : '';
    };
    ShareOverlay.prototype.getTranslations = function (shareMode) {
        return {
            titleText: this.getTitleByShareMode(shareMode),
            shareChannelText: i18n.t('share-overlay.social.share-channel'),
            shareVideoText: i18n.t('share-overlay.social.share-video'),
            twitterDescriptionText: i18n.t('social-share.network.twitter', {
                title: '{title}',
                siteUrl: '{siteUrl}',
            }),
            pinterestDescriptionText: i18n.t('social-share.network.pinterest', {
                title: '{title}',
                description: '{description}',
            }),
            tumblrDescriptionText: i18n.t('social-share.network.tumblr', {
                title: '{title}',
                siteUrl: '{siteUrl}',
            }),
            linkCopiedText: i18n.t('share-overlay.social.link-copied'),
            // tooltips
            embedTooltipText: i18n.t('share-overlay.social.embed-video'),
            facebookTooltipText: i18n.t('social-button.facebook'),
            linkTooltipText: i18n.t('share-overlay.social.copy-link'),
            pinterestTooltipText: i18n.t('social-button.pinterest'),
            tumblrTooltipText: i18n.t('social-button.tumblr'),
            twitterTooltipText: i18n.t('social-button.twitter'),
        };
    };
    ShareOverlay.prototype.getAriaLabels = function () {
        return {
            facebookAriaLabel: i18n.t('widget.accessibility.share-on', {
                network: 'Facebook',
            }),
            twitterAriaLabel: i18n.t('widget.accessibility.share-on', {
                network: 'Twitter',
            }),
            pinterestAriaLabel: i18n.t('widget.accessibility.share-on', {
                network: 'Pinterest',
            }),
            tumblrAriaLabel: i18n.t('widget.accessibility.share-on', {
                network: 'Tumblr',
            }),
            linkAriaLabel: i18n.t('widget.accessibility.get-link'),
            embedAriaLabel: i18n.t('widget.accessibility.get-embed-code'),
            moreAriaLabel: i18n.t('widget.accessibility.show-more'),
        };
    };
    ShareOverlay.prototype.reportStats = function (_a) {
        var networkName = _a.networkName, shareType = _a.shareType;
        var _b = this.props, reportStats = _b.reportStats, videoItem = _b.videoItem, channelData = _b.channelData;
        if (shareType === SHARE_MODES.VIDEO) {
            reportStats(STATS_EVENTS.VIDEO_SHARE, {
                channel: channelData,
                videoItem: videoItem,
                network: {
                    name: networkName,
                },
            });
        }
        else {
            reportStats(STATS_EVENTS.CHANNEL_SHARE, {
                channel: channelData,
                network: {
                    name: networkName,
                },
            });
        }
    };
    ShareOverlay.prototype.canShowSelect = function () {
        var _a = this.props, isChannelShareVisible = _a.isChannelShareVisible, isVideoShareVisible = _a.isVideoShareVisible, videoItem = _a.videoItem;
        return isChannelShareVisible && isVideoShareVisible && Boolean(videoItem);
    };
    ShareOverlay.prototype.getShareMode = function () {
        var _a = this.props, videoItem = _a.videoItem, shareMode = _a.shareMode;
        // it should always be in share channel mode if has no video
        return videoItem ? shareMode : SHARE_MODES.CHANNEL;
    };
    ShareOverlay.prototype.render = function () {
        var _a;
        var _b = this.props, instanceInfo = _b.instanceInfo, canShareContent = _b.canShareContent, channelData = _b.channelData, videoItem = _b.videoItem, isShareOverlayOpen = _b.isShareOverlayOpen, shouldShowEmbedButton = _b.shouldShowEmbedButton, videoUrl = _b.videoUrl, channelUrl = _b.channelUrl, compId = _b.compId, sitePageId = _b.sitePageId, shareMode = _b.shareMode, isRTL = _b.isRTL;
        var classNames = classnames('share-overlay-container', styles.wrapper, (_a = {},
            _a[styles.active] = isShareOverlayOpen,
            _a));
        var isEmbedOpen = this.state.isEmbedOpen;
        return (React.createElement("div", { className: classNames, "data-hook": "share-overlay", "aria-hidden": !isShareOverlayOpen, ref: this.saveRef },
            React.createElement(Animate, { type: "fade-in-out", className: "share-overlay-animate " + styles.content, when: isShareOverlayOpen },
                React.createElement(ClosableOverlay, { dataHook: "closable-overlay", onClose: this.closeShareOverlay, closeOnBodyClick: true, isFullWidth: true, isVisible: isShareOverlayOpen, isRTL: isRTL, ariaLabel: i18n.t('widget.accessibility.share'), closeButtonAriaLabel: i18n.t('widget.accessibility.close-share') },
                    React.createElement(ShareButtons, __assign({ channelData: channelData, videoItem: videoItem, shareMode: this.getShareMode(), instanceInfo: instanceInfo, canShareContent: canShareContent, onEmbedClicked: this.openEmbedForm, canShowSelect: this.canShowSelect(), onShare: this.handleShare, onLinkCopied: this.handleLinkCopied, shouldShowEmbedButton: shouldShowEmbedButton, videoUrl: videoUrl, channelUrl: channelUrl, isRTL: isRTL }, this.getTranslations(shareMode), this.getAriaLabels()), isEmbedOpen && (React.createElement(EmbedForm, { dataHook: "embed-form", onBack: this.closeEmbedForm, channelId: channelData.id, videoId: videoItem.id, compId: compId, sitePageId: sitePageId, isRTL: isRTL })))))));
    };
    ShareOverlay.propTypes = {
        channelData: PropTypes.object,
        videoItem: PropTypes.object,
        instanceInfo: PropTypes.object,
        canShareContent: PropTypes.bool,
        isShareOverlayOpen: PropTypes.bool,
        shouldShowEmbedButton: PropTypes.bool,
        isChannelShareVisible: PropTypes.bool,
        isVideoShareVisible: PropTypes.bool,
        canShareChannel: PropTypes.bool,
        canShareVideo: PropTypes.bool,
        isRTL: PropTypes.bool,
        isEditor: PropTypes.bool,
        shareMode: PropTypes.oneOf([SHARE_MODES.CHANNEL, SHARE_MODES.VIDEO]),
        compId: PropTypes.string,
        sitePageId: PropTypes.string,
        videoUrl: PropTypes.string,
        channelUrl: PropTypes.string,
        closeShareOverlay: PropTypes.func,
        onShare: PropTypes.func,
        onClosed: PropTypes.func,
        reportStats: PropTypes.func,
    };
    ShareOverlay.defaultProps = {
        onShare: _.noop,
        onClosed: _.noop,
    };
    ShareOverlay = __decorate([
        connect(function (state, ownProps) {
            var isPreview = viewModeSelectors.isPreviewMode(state);
            var isEditor = viewModeSelectors.isEditorMode(state);
            var canShareContent = !(isPreview || isEditor);
            return {
                shareMode: getShareMode(state),
                canShareContent: canShareContent,
                isEditor: isEditor,
                isChannelShareVisible: _.isUndefined(ownProps.canShareChannel)
                    ? canShowChannelShare(state)
                    : ownProps.canShareChannel,
                isVideoShareVisible: _.isUndefined(ownProps.canShareVideo)
                    ? canShareVideo(state)
                    : ownProps.canShareVideo,
                instanceInfo: __assign(__assign({}, getInstanceInfo(state)), { biToken: getBiToken(state) }),
                isShareOverlayOpen: isShareOverlayOpen(state),
                shouldShowEmbedButton: isEmbedEnabled(ownProps),
                compId: getCompId(state),
                sitePageId: getCurrentPageId(state),
                // TODO: get links to media share server
                videoUrl: getVideoUrl(state, ownProps),
                channelUrl: getChannelUrl(state),
                isRTL: isRTL(state),
            };
        }, {
            closeShareOverlay: closeShareOverlay,
            reportStats: reportStats,
            logBi: logBi,
        }),
        withStyles(styles)
    ], ShareOverlay);
    return ShareOverlay;
}(React.Component));
export default ShareOverlay;
