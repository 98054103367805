import { __assign, __read, __rest, __spread, __values } from "tslib";
var PREFIX = '@WORKER_ACTION';
var UNIQUE_FN_KEY = '____worker-fn-proxy-id';
var FUNCTION_EVENT_NAME = 'FUNCTION_CALL_EVENT';
function isProxyFn(val) {
    return (val !== null && typeof val === 'object' && val[UNIQUE_FN_KEY] !== undefined);
}
var createEvent = (function () {
    var getUniqueId = (function () {
        var id = 0;
        return function () { return ++id; };
    })();
    return function (name, data) {
        return { id: getUniqueId(), name: name, data: data };
    };
})();
function applyForAction(action, argCondition, apply) {
    if (!isWorkerAction(action) && !isProxyAction(action)) {
        return action;
    }
    var resolve = action.resolve, reject = action.reject, args = action.args, other = __rest(action, ["resolve", "reject", "args"]);
    args = args.map(function (arg) {
        if (!argCondition(arg)) {
            return arg;
        }
        return apply(arg);
    });
    if (other.isAsync) {
        resolve = apply(resolve);
        reject = apply(reject);
    }
    return __assign(__assign({}, other), { resolve: resolve, reject: reject, args: args });
}
export function restoreAction(_a) {
    var publishEvent = _a.publishEvent, action = _a.action;
    var restoreFn = function (fn) { return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return publishEvent(createEvent(FUNCTION_EVENT_NAME, {
            functionId: fn[UNIQUE_FN_KEY],
            args: args,
        }));
    }; };
    return applyForAction(action, isProxyFn, restoreFn);
}
export function createEventPublisher(_a) {
    var setProps = _a.setProps;
    var events = [];
    var publishEvent = function (event) {
        events = events.concat([event]);
        setProps({ events: events });
    };
    var consumeEvents = function (eventsIds) {
        var eventIdSet = new Set(eventsIds);
        events = events.filter(function (ev) { return !eventIdSet.has(ev.id); });
    };
    var getEvents = function () { return events; };
    return { publishEvent: publishEvent, consumeEvents: consumeEvents, getEvents: getEvents };
}
export function consumeEvents(props, cb) {
    var events = props.events || [];
    events.forEach(function (ev) { return cb(ev); });
    props.consumeEvents(events.map(function (ev) { return ev.id; }));
}
export function createFunctionProxy() {
    var functionsMap = new Map();
    var getUniqueId = (function () {
        var id = 0;
        return function () { return ++id; };
    })();
    function proxyfyFn(fn) {
        var _a;
        var fnId = getUniqueId();
        functionsMap.set(fnId, fn);
        return _a = {}, _a[UNIQUE_FN_KEY] = fnId, _a;
    }
    function serializeAction(action) {
        return applyForAction(action, function (arg) { return typeof arg === 'function'; }, proxyfyFn);
    }
    function callFunction(event) {
        if (event.name !== FUNCTION_EVENT_NAME) {
            return;
        }
        var _a = event.data, functionId = _a.functionId, args = _a.args;
        var origFn = functionsMap.get(functionId);
        if (!origFn) {
            console.error('calling callback function multiple times');
            return;
        }
        functionsMap.delete(functionId);
        origFn.apply(void 0, __spread(args));
    }
    return { serializeAction: serializeAction, callFunction: callFunction, proxyfyFn: proxyfyFn };
}
export function createWorkerAction(type) {
    var actionType = PREFIX + "/" + type;
    var actionCreator = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return ({
            type: actionType,
            args: args,
        });
    };
    actionCreator.type = actionType;
    return actionCreator;
}
var createBaseProxy = (function () {
    return function (type, fn) { return fn; };
})();
export var createProxy = createBaseProxy;
export var createAsyncProxy = function (type, fn) {
    return createBaseProxy(type, fn, { isAsync: true });
};
export function isProxyActionCreator() {
    return true;
}
export function isProxyAction(action) {
    return typeof action === 'object' && action.type.startsWith('@PROXY/');
}
export function isWorkerAction(action) {
    return action.type.startsWith(PREFIX);
}
export function createHandler(handlers) {
    var mapping = {};
    handlers.forEach(function (_a) {
        var _b = __read(_a, 2), actionCreator = _b[0], handler = _b[1];
        if (!actionCreator.type) {
            throw new Error('bad actionCreator, create it with createWorkerAction');
        }
        if (mapping[actionCreator.type]) {
            throw new Error('not unique type');
        }
        mapping[actionCreator.type] = handler;
    });
    return function (action) {
        if (!isWorkerAction(action)) {
            return;
        }
        var handler = mapping[action.type];
        if (!handler) {
            console.warn("can't find handler for action: " + JSON.stringify(action));
            return;
        }
        var args = action.args || [];
        handler.apply(void 0, __spread(args));
    };
}
export var createWorkerHandlerMiddleware = function (handler) { return function () { return function (next) { return function (action) {
    if (isWorkerAction(action)) {
        handler(action);
        return;
    }
    return next(action);
}; }; }; };
export function createTestProxiesMiddleware(allProxies) {
    var e_1, _a;
    var mapping = {};
    try {
        for (var allProxies_1 = __values(allProxies), allProxies_1_1 = allProxies_1.next(); !allProxies_1_1.done; allProxies_1_1 = allProxies_1.next()) {
            var actionCreator = allProxies_1_1.value;
            mapping[actionCreator.type] = actionCreator.origFn;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (allProxies_1_1 && !allProxies_1_1.done && (_a = allProxies_1.return)) _a.call(allProxies_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return function () { return function (next) { return function (action) {
        if (isProxyAction(action)) {
            var origFn = mapping[action.type];
            return next(origFn.apply(void 0, __spread(action.args)));
        }
        return next(action);
    }; }; };
}
export function createProxiesMiddleware(allProxies) {
    var e_2, _a;
    var mapping = {};
    try {
        for (var allProxies_2 = __values(allProxies), allProxies_2_1 = allProxies_2.next(); !allProxies_2_1.done; allProxies_2_1 = allProxies_2.next()) {
            var actionCreator = allProxies_2_1.value;
            mapping[actionCreator.type] = actionCreator.origFn;
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (allProxies_2_1 && !allProxies_2_1.done && (_a = allProxies_2.return)) _a.call(allProxies_2);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return function (store) { return function (next) { return function (action) {
        if (isProxyAction(action)) {
            var origFn = mapping[action.type];
            var args = action.args, resolve = action.resolve, reject = action.reject, isAsync = action.isAsync;
            var res = store.dispatch(origFn.apply(void 0, __spread(args)));
            if (isAsync) {
                res = res.then(resolve, reject);
            }
            return res;
        }
        return next(action);
    }; }; };
}
