import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from '../../../../../../shared/utils/withStyles';
import styles from './not-available-overlay.styl';
var NotAvailableOverlay = function (_a) {
    var width = _a.width, height = _a.height;
    var style = { width: width, height: height };
    return (React.createElement("div", { "data-hook": "video-not-avalilable-overlay", className: styles.overlay, style: style },
        React.createElement("div", { className: styles.title }, i18n.t('widget.video-is-not-available')),
        React.createElement("div", { className: styles.text }, i18n.t('widget.check-again-later'))));
};
NotAvailableOverlay.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};
export default withStyles(styles)(NotAvailableOverlay);
