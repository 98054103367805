import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fedopsLogger from '../../../../../loggers/fedops/mobile-widget';
import { getAllSettings } from '../../../../../shared/selectors/app-settings';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from '../../../../../shared/selectors/current-site-user';
import getVideoUrls from '../../../../../widget/selectors/get-video-urls';
import ResizedImage from '../../../../../shared/components/resized-image/resized-image';
import { openBuyVideoOverlayFromWidget, openSubscribeOverlayFromWidget, } from '../../../../../widget/redux/client/actions/mobile-overlay-openners/payment-pages-actions';
import { openVideoPage, visitChannel, } from '../../../../../widget/redux/client/actions/mobile-overlay-openners/main-openners';
import { isChannelCoverView, getTitle, getCover, } from '../../ui-selectors/cover-with-overlay';
import CoverActionsOverlay from '../cover-actions-overlay/cover-actions-overlay';
import { notForPreview } from '../../../../../shared/utils/not-for-preview';
var CoverWithOverlay = /** @class */ (function (_super) {
    __extends(CoverWithOverlay, _super);
    function CoverWithOverlay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onCoverClick = function () {
            var _a = _this.props, isChannelCoverView = _a.isChannelCoverView, visitChannel = _a.visitChannel, openVideoPage = _a.openVideoPage, videoItem = _a.videoItem;
            if (isChannelCoverView) {
                _this.props.logOpenChannelViewStart();
                visitChannel();
            }
            else {
                _this.props.logOpenVideoViewStart();
                openVideoPage(videoItem.id);
            }
        };
        _this.onBuyClick = notForPreview(function () {
            var _a = _this.props, videoItem = _a.videoItem, openBuyVideoOverlay = _a.openBuyVideoOverlay;
            openBuyVideoOverlay(videoItem.id);
        });
        _this.handleSubscribeClick = notForPreview(function () {
            _this.props.openSubscribeOverlay();
        });
        return _this;
    }
    CoverWithOverlay.prototype.render = function () {
        var _a = this.props, isChannelCoverView = _a.isChannelCoverView, isFirstVideo = _a.isFirstVideo, onSignInRequested = _a.onSignInRequested, onPlayRequestedAllowed = _a.onPlayRequestedAllowed, onPlayRequestedDisallowed = _a.onPlayRequestedDisallowed, onMemberSignUp = _a.onMemberSignUp, itemWidth = _a.itemWidth, itemHeight = _a.itemHeight, className = _a.className, coverUrl = _a.coverUrl, videoItem = _a.videoItem, channelData = _a.channelData, currentSiteUser = _a.currentSiteUser, title = _a.title;
        return (React.createElement(ResizedImage, { className: className, src: coverUrl, width: itemWidth, height: itemHeight, withFallbackColor: true, isMobile: true },
            React.createElement(CoverActionsOverlay, { onPlayRequestedAllowed: onPlayRequestedAllowed, onPlayRequestedDisallowed: onPlayRequestedDisallowed, onMemberSignUp: onMemberSignUp, onSignInRequested: onSignInRequested, onSubscribeClick: this.handleSubscribeClick, onCoverClick: this.onCoverClick, onBuyClick: this.onBuyClick, videoItem: videoItem, channelData: channelData, currentSiteUser: currentSiteUser, title: title, isChannelCoverView: isChannelCoverView, isFirstVideo: isFirstVideo })));
    };
    CoverWithOverlay.propTypes = {
        videoItem: PropTypes.object,
        channelData: PropTypes.object,
        currentSiteUser: PropTypes.object,
        videoUrls: PropTypes.object,
        appSettings: PropTypes.object,
        openVideoPage: PropTypes.func,
        visitChannel: PropTypes.func,
        openBuyVideoOverlay: PropTypes.func,
        onSignInRequested: PropTypes.func,
        openSubscribeOverlay: PropTypes.func,
        onPlayRequestedAllowed: PropTypes.func,
        onPlayRequestedDisallowed: PropTypes.func,
        onMemberSignUp: PropTypes.func,
        isChannelCoverView: PropTypes.bool,
        isFirstVideo: PropTypes.bool,
        title: PropTypes.string,
        className: PropTypes.string,
        coverUrl: PropTypes.string,
        itemWidth: PropTypes.number,
        itemHeight: PropTypes.number,
    };
    CoverWithOverlay = __decorate([
        connect(function (state, props) { return ({
            channelData: getChannelForWidget(state),
            currentSiteUser: getCurrentSiteUser(state),
            videoUrls: getVideoUrls(state, props),
            isChannelCoverView: isChannelCoverView(state, props),
            coverUrl: getCover(state, props),
            title: getTitle(state, props),
            appSettings: getAllSettings(state),
        }); }, {
            openBuyVideoOverlay: openBuyVideoOverlayFromWidget,
            openSubscribeOverlay: openSubscribeOverlayFromWidget,
            openVideoPage: openVideoPage,
            visitChannel: visitChannel,
            logOpenChannelViewStart: fedopsLogger.openChannelView.start,
            logOpenVideoViewStart: fedopsLogger.openVideoView.start,
        })
    ], CoverWithOverlay);
    return CoverWithOverlay;
}(React.Component));
export default CoverWithOverlay;
