import { __decorate, __extends } from "tslib";
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isLoadMoreDisplayedAsLink, areElementsRounded, canShowVideoListItemContentBelow, } from '../../../../../shared/selectors/app-settings';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './load-more-button.styl';
var LoadMoreButton = /** @class */ (function (_super) {
    __extends(LoadMoreButton, _super);
    function LoadMoreButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoadMoreButton.prototype.getClassName = function () {
        var _a = this.props, isLink = _a.isLink, isRounded = _a.isRounded;
        if (isLink) {
            return styles.link;
        }
        if (isRounded) {
            return styles.rounded;
        }
        return null;
    };
    LoadMoreButton.prototype.render = function () {
        var _a, _b;
        var _c = this.props, isThumbnailsHaveContentBelow = _c.isThumbnailsHaveContentBelow, isLoading = _c.isLoading, children = _c.children, onClick = _c.onClick, ariaLabel = _c.ariaLabel, dataHook = _c.dataHook;
        var className = classnames(styles.button, this.getClassName(), (_a = {},
            _a[styles.loading] = isLoading,
            _a));
        return (React.createElement("div", { className: classnames(styles.container, (_b = {},
                _b[styles.responsive] = isThumbnailsHaveContentBelow,
                _b)) },
            React.createElement("button", { "aria-label": ariaLabel, "data-hook": dataHook, tabIndex: "0", onClick: onClick, className: className }, children)));
    };
    LoadMoreButton.propTypes = {
        isRounded: PropTypes.bool.isRequired,
        isThumbnailsHaveContentBelow: PropTypes.bool.isRequired,
        isLink: PropTypes.bool,
        ariaLabel: PropTypes.string,
        dataHook: PropTypes.string,
        children: PropTypes.node,
        onClick: PropTypes.func,
        isLoading: PropTypes.bool,
    };
    LoadMoreButton.defaultProps = {
        isLink: false,
    };
    LoadMoreButton = __decorate([
        connect(function (state) { return ({
            isRounded: areElementsRounded(state),
            isLink: isLoadMoreDisplayedAsLink(state),
            isThumbnailsHaveContentBelow: canShowVideoListItemContentBelow(state),
        }); }),
        withStyles(styles)
    ], LoadMoreButton);
    return LoadMoreButton;
}(React.Component));
export default LoadMoreButton;
