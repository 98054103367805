import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '../../../../../../shared/utils/withStyles';
import styles from '../overlay.styl';
var OverlayPublisher = /** @class */ (function (_super) {
    __extends(OverlayPublisher, _super);
    function OverlayPublisher() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OverlayPublisher.prototype.render = function () {
        var _a = this.props, children = _a.children, className = _a.className, ariaHidden = _a.ariaHidden;
        if (!children) {
            return null;
        }
        return (React.createElement("div", { className: classnames(styles.title, className), "data-hook": "publisher", "aria-hidden": ariaHidden }, children));
    };
    OverlayPublisher.propTypes = {
        className: PropTypes.string,
        children: PropTypes.string,
        ariaHidden: PropTypes.bool,
    };
    OverlayPublisher.defaultProps = {
        ariaHidden: false,
    };
    OverlayPublisher = __decorate([
        withStyles(styles)
    ], OverlayPublisher);
    return OverlayPublisher;
}(React.Component));
export default OverlayPublisher;
