import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from '../../../../shared/utils/withStyles';
import styles from './no-results.styl';
var NoResults = function (_a) {
    var className = _a.className, dataHook = _a.dataHook, message = _a.message, onButtonClick = _a.onButtonClick;
    return (React.createElement("section", { "data-hook": dataHook, tabIndex: "0", className: classnames(styles.container, className) },
        React.createElement("div", null,
            React.createElement("div", { className: styles.title }, message),
            React.createElement("button", { "data-hook": "reset-search-button", className: styles.button, onClick: onButtonClick }, i18n.t('widget.search.clear-search')))));
};
NoResults.propTypes = {
    className: PropTypes.string,
    dataHook: PropTypes.string,
    message: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
};
export default withStyles(styles)(NoResults);
