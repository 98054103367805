import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { canShowActionBar } from '../../selectors/layout';
import { canShowChannelCategories } from '../../selectors/categories';
import CategoriesDropdown from './categories-dropdown/categories-dropdown';
import ChannelActionsContainer from '../../../shared/containers/channel-actions/channel-actions';
import Menu from './menu/menu';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './action-bar.styl';
var ActionBar = /** @class */ (function (_super) {
    __extends(ActionBar, _super);
    function ActionBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActionBar.prototype.renderChannelMenu = function () {
        var _a = this.props, channelData = _a.channelData, onPageRefresh = _a.onPageRefresh;
        return (React.createElement(ChannelActionsContainer, { channelId: channelData.id, onPageRefresh: onPageRefresh }, function (channelActions) { return (React.createElement(Menu, { channel: channelData, onLogInRequest: channelActions.logIn, onShowAccountInfoRequest: channelActions.showAccountInfo, onSubscriptionRequest: channelActions.subscribe, onCancelSubscriptionRequest: channelActions.cancelSubscription, onInfoRequest: channelActions.showInfo, onShareRequest: channelActions.showShare })); }));
    };
    ActionBar.prototype.render = function () {
        var _a;
        var _b = this.props, style = _b.style, className = _b.className, canShowChannelCategories = _b.canShowChannelCategories, canShowActionBar = _b.canShowActionBar, isResponsive = _b.isResponsive;
        if (!canShowActionBar) {
            return null;
        }
        return (React.createElement("section", { "data-hook": "action-bar", className: classnames(styles.container, className, (_a = {},
                _a[styles.responsive] = isResponsive,
                _a)), style: style },
            React.createElement("div", { className: styles.menu }, this.renderChannelMenu()),
            canShowChannelCategories && (React.createElement(CategoriesDropdown, { className: styles.categories }))));
    };
    ActionBar.propTypes = {
        canShowChannelCategories: PropTypes.bool,
        isResponsive: PropTypes.bool,
        className: PropTypes.string,
        channelData: PropTypes.object.isRequired,
        onPageRefresh: PropTypes.func.isRequired,
        canShowActionBar: PropTypes.bool.isRequired,
        style: PropTypes.object,
    };
    ActionBar.defaultProps = {
        onPageRefresh: _.noop,
    };
    ActionBar = __decorate([
        connect(function (state) { return ({
            canShowActionBar: canShowActionBar(state),
            canShowChannelCategories: canShowChannelCategories(state),
        }); }),
        withStyles(styles)
    ], ActionBar);
    return ActionBar;
}(React.Component));
export default ActionBar;
