import { __assign } from "tslib";
import events from '../../constants/events';
import pubsub from '../../utils/pubsub';
export var DISMISSED = Symbol('dismissed');
export var CANCELLED = Symbol('cancelled');
export function isClose(reason) {
    return reason === DISMISSED;
}
export function isCancel(reason) {
    return reason === CANCELLED;
}
export function closeCatcher(reason) {
    if (isClose(reason)) {
        return;
    }
    return Promise.reject(reason);
}
export function openModal(config) {
    return new Promise(function (resolve, reject) {
        pubsub.publish(events.MODAL.OPEN, __assign(__assign({}, config), { resolve: resolve,
            reject: reject }));
    });
}
export function closeModal() {
    pubsub.publish(events.MODAL.CLOSE);
}
