import { __assign, __decorate, __extends } from "tslib";
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { VIDEO_CARDS_BI_ORIGIN } from '@wix/wix-vod-constants/dist/bi/video-cards-origin';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { isOwned } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/pricing';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import Spinner from '@wix/wix-vod-shared/dist/src/widget/ui-components/loading-spinner/loading-spinner';
import { canAccessFullVideo, canPlayFullOrPreview, getOwnershipStatus, isDownloadAllowed, getFullAccessActionName, FULL_ACCESS_ACTION_NAMES, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isRTL, areElementsRounded, isCommentsEnabled, isCommentsEnabledForLive, isSingleVideo, } from '../../../../shared/selectors/app-settings';
import { loadVODPlayer } from '../../../../shared/utils/load-player';
import ActionsPanel from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/actions-panel/actions-panel';
import AllVideoCards from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/video-cards/all-cards/all-cards';
import VideoCards from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/video-cards/video-cards';
import { makeCancelable } from '@wix/wix-vod-shared/dist/src/widget/utils/make-cancelable-promise';
import { getDownloadUrl } from '@wix/wix-vod-api/dist/src/public/video/video';
import { logBi } from '../../../../shared/worker/actions/bi';
import { getInstance } from '../../../redux/client/hydrated-data/hydrated-data';
import { isDownloadEnabled } from '../../../../shared/selectors/downloads';
import { isInFullScreen, isAutoplayed, isPlayerUIVisible, getOverlayContent, } from '../../../../shared/selectors/player';
import { getBuyRentTextForPlayer } from '../../../../shared/utils/buy-button-texts/buy-button-texts';
import getPlayerTranslations from '../../../../shared/utils/get-player-translations';
import getVidiUrls, { getDownloadUrl as getDownloadUrlGql, } from '../../../../shared/selectors/vidi-urls';
import { notForPreview } from '../../../../shared/utils/not-for-preview';
import ShareOverlay from '../../../containers/share-overlay/share-overlay';
import videoCardsBiLogger from './bi-loggers/video-cards-bi-logger';
import { pauseVideo, playVideo, resetPlaybackStatus, } from '../../../redux/client/actions/player/change-playback-status';
import { setSavedTime } from '../../../redux/client/actions/player/set-saved-time';
import { hidePlayerUI, showPlayerUI, } from '../../../redux/client/actions/player/ui';
import { setOverlayContent } from '../../../redux/client/actions/player/set-overlay-content';
import { toggleShareVideoOverlay } from '../../../redux/client/actions/share-overlay';
import { canShowComments, hasNewComments } from '../../../selectors/comments';
import { getCurrentSiteUser } from '../../../../shared/selectors/current-site-user';
import { isShareOverlayOpen } from '../../../selectors/share-overlay';
import { getVideoCardsList } from '../../../selectors/video-cards';
import { setCommentsOpen } from '../../../redux/client/actions/comments.js';
import VideoCardsWrapper from './components/video-cards-wrapper-desktop/video-cards-wrapper-desktop';
import AnnotoHandlers from './components/annoto-handlers/annoto-handlers';
import AnimatedOverlayContent from './containers/animated-overlay-content/animated-overlay-content';
import INFO_OVERLAY_CONTENT from './containers/animated-overlay-content/info-overlay-content-enums';
import { LiveVideoUpdaters } from './containers/live-video-updaters/live-video-updaters';
import Loggers from './containers/loggers/loggers';
import PlayerUIVisibilityManager from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/ui-visibility-manager/ui-visibility-manager';
import PlayableFullScreenManager from 'playable/dist/src/modules/full-screen-manager/full-screen-manager';
import * as viewModeSelectors from '../../../selectors/view-mode';
var logAllCardsButtonClick = videoCardsBiLogger.logAllCardsButtonClick, logAllCardsCloseButtonClick = videoCardsBiLogger.logAllCardsCloseButtonClick;
var FullScreenManager = /** @class */ (function (_super) {
    __extends(FullScreenManager, _super);
    function FullScreenManager() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.enterFullScreen = notForPreview(function () {
            _super.prototype.enterFullScreen.call(_this);
        });
        return _this;
    }
    return FullScreenManager;
}(PlayableFullScreenManager));
var InternalPlayer = /** @class */ (function (_super) {
    __extends(InternalPlayer, _super);
    function InternalPlayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            VideoPlayerWrapper: null,
            VODplayer: null,
            vidiUrls: null,
        };
        _this.onEnded = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            _this.closeOpenOverlays();
            _this.props.onEnded(args);
        };
        _this.onPlayerInit = function (player) {
            if (_this.isUnmounted) {
                return;
            }
            _this.setState({
                player: player,
                eventEmitter: player._defaultModules.eventEmitter,
                engine: player._defaultModules.engine,
            });
        };
        _this.reportAnomaly = function () {
            // getNewRelic();
        };
        _this.handleOverlayClose = function () {
            if (_this.props.overlayContent === INFO_OVERLAY_CONTENT.ALL_CARDS) {
                _this.props.logAllCardsCloseButtonClick();
            }
            _this.closeOpenOverlays();
        };
        _this.closeOpenOverlays = function () {
            _this.props.showPlayerUI();
            if (_this.props.overlayContent) {
                _this.props.setOverlayContent(null);
            }
        };
        _this.openShareOverlay = function () {
            var _a = _this.props, toggleShareVideoOverlay = _a.toggleShareVideoOverlay, hidePlayerUI = _a.hidePlayerUI;
            hidePlayerUI();
            toggleShareVideoOverlay();
        };
        _this.openVideoInfoOverlay = function () {
            _this.props.hidePlayerUI();
            _this.props.setOverlayContent(INFO_OVERLAY_CONTENT.VIDEO_INFO);
        };
        _this.handleAllCardsButtonClick = function () {
            _this.props.hidePlayerUI();
            _this.props.setOverlayContent(INFO_OVERLAY_CONTENT.ALL_CARDS);
            _this.props.logAllCardsButtonClick();
        };
        _this.getBuyClickHandler = function () {
            var _a = _this.props, videoItem = _a.videoItem, channelData = _a.channelData;
            var status = getFullAccessActionName(channelData, videoItem);
            switch (status) {
                case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
                    return _this.proxySubscribeRequest;
                case FULL_ACCESS_ACTION_NAMES.RENT:
                    return _this.proxyRentRequest;
                default:
                    return _this.proxyPurchaseRequest;
            }
        };
        _this.proxySubscribeRequest = function () {
            var onSubscribeRequest = _this.props.onSubscribeRequest;
            onSubscribeRequest();
        };
        _this.proxyPurchaseRequest = function () {
            var onPurchaseRequest = _this.props.onPurchaseRequest;
            onPurchaseRequest();
        };
        _this.proxyRentRequest = function () {
            var onRentRequest = _this.props.onRentRequest;
            onRentRequest();
        };
        _this.onPlayAbort = function () {
            // todo add bi
            var _a = _this.props, resetPlaybackStatus = _a.resetPlaybackStatus, abortPlayVideo = _a.abortPlayVideo, videoItem = _a.videoItem, isInLightbox = _a.isInLightbox, isLive = _a.isLive;
            if (isInLightbox) {
                resetPlaybackStatus(videoItem.id);
            }
            else if (isLive) {
                abortPlayVideo(videoItem.id);
            }
        };
        _this.handleDownloadVideo = function () {
            var _a = _this.props, isLive = _a.isLive, instance = _a.instance, videoItem = _a.videoItem, channelData = _a.channelData;
            _this.props.logBi('widget.download.video.clicked', {
                channelId: channelData.id,
                videoId: videoItem.id,
                videoType: isOwned(videoItem) ? 'purchased' : 'free',
                isLive: isLive,
                duration: videoItem.durationSec * 1000,
            });
            var downloadUrl = _this.props.downloadUrl ||
                getDownloadUrl(videoItem.itemId, channelData.id, instance);
            window.open(downloadUrl, '_blank');
        };
        _this.handleCommentsButtonClick = function () {
            _this.state.player.showAnnoto();
        };
        return _this;
    }
    InternalPlayer.prototype.componentDidMount = function () {
        var _this = this;
        this.loadPlayerPromise = makeCancelable(loadVODPlayer());
        this.loadPlayerPromise.promise
            .then(function (VODplayerModule) {
            _this.setState({ VODplayer: VODplayerModule.default });
        })
            .catch(function (e) {
            if (e.isCanceled) {
                return;
            }
            return Promise.reject(e);
        });
    };
    InternalPlayer.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        if (!this.state.vidiUrls ||
            !_.isEqual(this.props.vidiUrls, nextProps.vidiUrls)) {
            this.setState({
                vidiUrls: nextProps.vidiUrls,
            });
        }
        this.shouldCloseOverlay(nextProps);
    };
    InternalPlayer.prototype.componentWillUnmount = function () {
        this.loadPlayerPromise.cancel();
        this.isUnmounted = true;
    };
    InternalPlayer.prototype.shouldCloseOverlay = function (nextProps) {
        var id = nextProps.videoItem.id, isVideoPlaying = nextProps.isVideoPlaying, isShareOverlayOpen = nextProps.isShareOverlayOpen;
        var isPlayStarted = isVideoPlaying && !this.props.isVideoPlaying;
        var isVideoIdChanged = this.props.videoItem.id !== id;
        var hasClosedOverlay = !isShareOverlayOpen && this.props.isShareOverlayOpen;
        if (isPlayStarted || isVideoIdChanged || hasClosedOverlay) {
            this.closeOpenOverlays();
        }
    };
    InternalPlayer.prototype.getBuyRentButtonText = function () {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem;
        return getBuyRentTextForPlayer({ channelData: channelData, videoItem: videoItem });
    };
    InternalPlayer.prototype.getVideoOwnershipStatus = function () {
        var videoItem = this.props.videoItem;
        return getOwnershipStatus(videoItem);
    };
    InternalPlayer.prototype.getFullScreenShareOverlay = function () {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem, mobileMode = _a.mobileMode, isInFullScreen = _a.isInFullScreen;
        if (mobileMode) {
            return null;
        }
        if (!isInFullScreen) {
            return null;
        }
        return (React.createElement(ShareOverlay, { key: "share-" + videoItem.id, channelData: channelData, videoItem: videoItem }));
    };
    InternalPlayer.prototype.getActionButtonLabels = function () {
        return {
            videoInfoFullText: i18n.t('widget.action-button-labels.video-info-full'),
            videoInfoShortText: i18n.t('widget.action-button-labels.video-info-short'),
            shareVideoFullText: i18n.t('widget.action-button-labels.share-video-full'),
            shareVideoShortText: i18n.t('widget.action-button-labels.share-video-short'),
            showAllCardsFullText: i18n.t('widget.action-button-labels.show-all-cards-full'),
            showAllCardsShortText: i18n.t('widget.action-button-labels.show-all-cards-short'),
            commentsFullText: i18n.t('widget.action-button-labels.show-comments-full'),
            commentsShortText: i18n.t('widget.action-button-labels.show-comments-short'),
        };
    };
    InternalPlayer.prototype.getVideoCardsAccessibilityLabels = function () {
        return {
            facebookButtonTitle: i18n.t('widget.accessibility.action-cards.facebook-like-button'),
            youtubeButtonTitle: i18n.t('widget.accessibility.action-cards.youtube-subscribe-button'),
            twitterButtonTitle: i18n.t('widget.accessibility.action-cards.twitter-follow-button'),
        };
    };
    InternalPlayer.prototype.validateVideoElement = function () {
        // this is here to perform an action on video element on user action, so browser will allow further autoplay
        var player = this.state.player;
        if (this.isVideoElementValidated || !player || player._destroyed) {
            return;
        }
        var videoElement = player._defaultModules.engine.getElement();
        if (!videoElement) {
            return;
        }
        videoElement.load();
        this.isVideoElementValidated = true;
    };
    InternalPlayer.prototype.render = function () {
        var _this = this;
        var _a = this.props, 
        // video player config
        mobileMode = _a.mobileMode, width = _a.width, height = _a.height, isAutoplayed = _a.isAutoplayed, isVideoPlayRequested = _a.isVideoPlayRequested, isVideoPlaying = _a.isVideoPlaying, isVideoPaused = _a.isVideoPaused, isVideoPauseRequested = _a.isVideoPauseRequested, playAtFullScreen = _a.playAtFullScreen, playInline = _a.playInline, onError = _a.onError, videoItem = _a.videoItem, channelData = _a.channelData, onPlay = _a.onPlay, onPause = _a.onPause, onFullScreenChanged = _a.onFullScreenChanged, cardsData = _a.cardsData, preload = _a.preload, isLive = _a.isLive, isPlayableVideo = _a.isPlayableVideo, isDownloadAllowed = _a.isDownloadAllowed, canAccessFullVideo = _a.canAccessFullVideo, canDownloadVideo = _a.canDownloadVideo, muted = _a.muted, overlayContent = _a.overlayContent, onVolumeChange = _a.onVolumeChange, onMuteChange = _a.onMuteChange, isRTL = _a.isRTL, fillAllSpace = _a.fillAllSpace, 
        // actions panel config
        areElementsRounded = _a.areElementsRounded, canShowFullInfo = _a.canShowFullInfo, canShareVideo = _a.canShareVideo, canShowAllCards = _a.canShowAllCards, canShowTitle = _a.canShowTitle, canShowBuyRentButton = _a.canShowBuyRentButton, canShowComments = _a.canShowComments, isCommentsEnabled = _a.isCommentsEnabled, hasNewComments = _a.hasNewComments, 
        // animated content config
        onShareRequest = _a.onShareRequest, isEditorViewMode = _a.isEditorViewMode, isSiteViewMode = _a.isSiteViewMode;
        var vidiUrls = this.state.vidiUrls;
        var _b = this.state, engine = _b.engine, eventEmitter = _b.eventEmitter, player = _b.player, VODplayer = _b.VODplayer;
        if (!isPlayableVideo) {
            return null;
        }
        if (!VODplayer) {
            return React.createElement(Spinner, null);
        }
        if (isVideoPlayRequested && _.isEmpty(vidiUrls)) {
            this.validateVideoElement();
        }
        var buyRentButtonText = this.getBuyRentButtonText();
        var prevButtonAriaLabel = i18n.t('widget.accessibility.action-cards.all-cards.next-page');
        var nextButtonAriaLabel = i18n.t('widget.accessibility.action-cards.all-cards.previous-page');
        var getSlideAriaLabel = function (variables) {
            return i18n.t('widget.accessibility.action-cards.all-cards.slide-contents', variables);
        };
        var isAnnotoModuleEnabled = (isCommentsEnabled && !mobileMode) || isEditorViewMode;
        return (React.createElement(VODplayer, { width: width, height: height, preload: preload, mobileMode: mobileMode, vidiUrls: vidiUrls, videoItem: videoItem, channelData: channelData, isVideoPlayRequested: !_.isEmpty(vidiUrls) && isVideoPlayRequested, isVideoPauseRequested: isVideoPauseRequested, playAtFullScreen: playAtFullScreen, playInline: playInline, texts: getPlayerTranslations(), canShowTitle: canShowTitle, onPlay: onPlay, onPause: onPause, onError: onError, canDownload: canDownloadVideo && isDownloadAllowed && !mobileMode && !isLive, onDownloadClick: this.handleDownloadVideo, muted: muted, onPlayAbort: this.onPlayAbort, onEnded: this.onEnded, onAnomalyDetected: this.reportAnomaly, onFullScreenChanged: onFullScreenChanged, onInit: this.onPlayerInit, onMuteChange: onMuteChange, onVolumeChange: onVolumeChange, isAnnotoModuleEnabled: isAnnotoModuleEnabled, modules: {
                fullScreenManager: FullScreenManager,
            }, isRTL: isRTL, fillAllSpace: fillAllSpace },
            !mobileMode && (React.createElement(ActionsPanel, __assign({ videoItem: videoItem, isFocusable: isVideoPlaying || isVideoPaused, areElementsRounded: areElementsRounded, canShowBuyRentButton: canShowBuyRentButton && Boolean(buyRentButtonText), canShowTitle: canShowTitle, canShowAllCardsButton: canShowAllCards, canShowCommentsButton: canShowComments, shouldShowCommentsPimple: hasNewComments, canShowFullInfo: canShowFullInfo, canShareVideo: canShareVideo, onShareVideoShow: this.openShareOverlay, onBuyRentButtonClick: this.getBuyClickHandler(), onAllCardsButtonClick: this.handleAllCardsButtonClick, onCommentsButtonClick: this.handleCommentsButtonClick, videoOwnershipStatus: this.getVideoOwnershipStatus(), onVideoInfoShow: this.openVideoInfoOverlay, buyRentButtonText: buyRentButtonText }, this.getActionButtonLabels(), { infoButtonAriaLabel: i18n.t('widget.accessibility.show-video-info'), shareButtonAriaLabel: i18n.t('widget.accessibility.share'), allCardsButtonAriaLabel: i18n.t('widget.accessibility.all-cards'), commentsButtonAriaLabel: i18n.t('widget.accessibility.video-chat'), isRTL: isRTL }))),
            !mobileMode && (React.createElement(AnimatedOverlayContent, { videoItem: videoItem, channelData: channelData, overlayContent: overlayContent, onShareRequest: onShareRequest, onClose: this.handleOverlayClose, allCardsOverlayContent: React.createElement(VideoCardsWrapper, { thumbnailSize: 300, biOrigin: VIDEO_CARDS_BI_ORIGIN.ALL_CARDS, cardsData: cardsData }, function (_a) {
                    var cardsData = _a.cardsData, onCardClick = _a.onCardClick;
                    return (React.createElement(AllVideoCards, { cardsData: cardsData, onCardClick: onCardClick, getSlideAriaLabel: getSlideAriaLabel, prevButtonAriaLabel: prevButtonAriaLabel, nextButtonAriaLabel: nextButtonAriaLabel, labels: _this.getVideoCardsAccessibilityLabels() }));
                }) })),
            !mobileMode && player && !player._destroyed && (React.createElement(VideoCardsWrapper, { cardsData: cardsData, player: player, biOrigin: VIDEO_CARDS_BI_ORIGIN.VIDEO }, function (_a) {
                var cardsData = _a.cardsData, onCardClick = _a.onCardClick;
                return (React.createElement(VideoCards, { cardsData: cardsData, player: player, onCardClick: onCardClick, labels: _this.getVideoCardsAccessibilityLabels() }));
            })),
            !mobileMode && (React.createElement(PlayerUIVisibilityManager, { isPlayerUIVisible: this.props.isPlayerUIVisible })),
            player && !player._destroyed && (React.createElement(Loggers, { engine: engine, eventEmitter: eventEmitter, videoItem: videoItem, channelData: channelData, isVideoPlaying: isVideoPlaying, isLive: isLive, isAutoplayed: isAutoplayed })),
            player && !player._destroyed && isLive && (React.createElement(LiveVideoUpdaters, { eventEmitter: eventEmitter, videoItem: videoItem, channelId: channelData.id, canAccessFullVideo: canAccessFullVideo, isSiteMode: isSiteViewMode })),
            this.getFullScreenShareOverlay(),
            player && isCommentsEnabled && !mobileMode && (React.createElement(AnnotoHandlers, { player: player, videoItem: videoItem, channelData: channelData, mobileMode: mobileMode }))));
    };
    InternalPlayer.propTypes = {
        appSettings: PropTypes.object,
        channelData: PropTypes.object,
        videoItem: PropTypes.object,
        instance: PropTypes.string,
        vidiUrls: PropTypes.array,
        className: PropTypes.string,
        mobileMode: PropTypes.bool,
        playAtFullScreen: PropTypes.bool,
        playInline: PropTypes.bool,
        width: PropTypes.number,
        height: PropTypes.number,
        preload: PropTypes.string,
        onPlayRequest: PropTypes.func,
        isEditorViewMode: PropTypes.bool.isRequired,
        isSiteViewMode: PropTypes.bool.isRequired,
        onPlay: PropTypes.func,
        onPause: PropTypes.func,
        onEnded: PropTypes.func,
        onError: PropTypes.func,
        onSubscribeRequest: PropTypes.func,
        onPurchaseRequest: PropTypes.func,
        onRentRequest: PropTypes.func,
        onFullScreenChanged: PropTypes.func,
        onShareRequest: PropTypes.func,
        onVideoInfoShow: PropTypes.func,
        downloadVideo: PropTypes.func,
        onVolumeChange: PropTypes.func,
        onMuteChange: PropTypes.func,
        isAutoplayed: PropTypes.bool,
        isVideoPlayRequested: PropTypes.bool,
        isVideoPauseRequested: PropTypes.bool,
        isVideoPlaying: PropTypes.bool,
        isVideoPaused: PropTypes.bool,
        isShareOverlayOpen: PropTypes.bool,
        isLive: PropTypes.bool,
        isInFullScreen: PropTypes.bool,
        isPlayableVideo: PropTypes.bool,
        muted: PropTypes.bool,
        canAccessFullVideo: PropTypes.bool,
        isDownloadAllowed: PropTypes.bool,
        cardsData: PropTypes.array,
        // actions panel config
        areElementsRounded: PropTypes.bool,
        canShowFullInfo: PropTypes.bool,
        canShareVideo: PropTypes.bool,
        canShowAllCards: PropTypes.bool,
        canShowTitle: PropTypes.bool,
        canShowBuyRentButton: PropTypes.bool,
        canShowComments: PropTypes.bool,
        isCommentsEnabled: PropTypes.bool,
        canDownloadVideo: PropTypes.bool,
        fillAllSpace: PropTypes.bool,
        toggleShareVideoOverlay: PropTypes.func,
        showPlayerUI: PropTypes.func,
        hidePlayerUI: PropTypes.func,
        playVideo: PropTypes.func,
        pauseVideo: PropTypes.func,
        resetPlaybackStatus: PropTypes.func,
        abortPlayVideo: PropTypes.func,
        currentSiteUser: PropTypes.object,
        isInLightbox: PropTypes.bool,
        isSingleVideo: PropTypes.bool,
        setCommentsOpen: PropTypes.func,
        hasNewComments: PropTypes.bool,
        setOverlayContent: PropTypes.func,
        overlayContent: PropTypes.string,
        isRTL: PropTypes.bool,
    };
    InternalPlayer.defaultProps = {
        onPlay: _.noop,
        onPause: _.noop,
        onEnded: _.noop,
        onError: _.noop,
        onFullScreenChanged: _.noop,
        onShareRequest: _.noop,
        onVideoInfoShow: _.noop,
        onVolumeChange: _.noop,
        width: 640,
        height: 360,
        preload: 'none',
        onPlayRequest: _.noop,
        canShowTitle: true,
        canShowBuyRentButton: true,
        isPlayableVideo: true,
        muted: false,
    };
    InternalPlayer = __decorate([
        connect(function (state, ownProps) {
            var cardsData = getVideoCardsList(state);
            var videoItem = _.get(ownProps, 'videoItem');
            var channelData = _.get(ownProps, 'channelData');
            var currentSiteUser = getCurrentSiteUser(state);
            return {
                vidiUrls: getVidiUrls(ownProps),
                cardsData: cardsData,
                canShowAllCards: !_.isEmpty(cardsData),
                canShowComments: canShowComments(state, videoItem, channelData, currentSiteUser),
                downloadUrl: getDownloadUrlGql(ownProps),
                isEditorViewMode: viewModeSelectors.isEditorMode(state),
                isSiteViewMode: viewModeSelectors.isSiteMode(state),
                isPlayerUIVisible: isPlayerUIVisible(state),
                isCommentsEnabled: isCommentsEnabled(state) || isCommentsEnabledForLive(state),
                areElementsRounded: areElementsRounded(state),
                isAutoplayed: isAutoplayed(state),
                isInFullScreen: isInFullScreen(state),
                isShareOverlayOpen: isShareOverlayOpen(state),
                isLive: isLiveVideo(videoItem),
                canAccessFullVideo: canAccessFullVideo(channelData, videoItem, currentSiteUser),
                isDownloadAllowed: isDownloadAllowed(channelData, videoItem, currentSiteUser),
                isPlayableVideo: canPlayFullOrPreview(channelData, videoItem, currentSiteUser),
                currentSiteUser: currentSiteUser,
                isSingleVideo: isSingleVideo(state),
                canDownloadVideo: isDownloadEnabled(state, videoItem.id),
                instance: getInstance(state),
                hasNewComments: hasNewComments(state),
                overlayContent: getOverlayContent(state),
                isRTL: isRTL(state),
            };
        }, {
            toggleShareVideoOverlay: toggleShareVideoOverlay,
            showPlayerUI: showPlayerUI,
            hidePlayerUI: hidePlayerUI,
            playVideo: playVideo,
            pauseVideo: pauseVideo,
            resetPlaybackStatus: resetPlaybackStatus,
            setSavedTime: setSavedTime,
            setCommentsOpen: setCommentsOpen,
            setOverlayContent: setOverlayContent,
            logBi: logBi,
            logAllCardsButtonClick: logAllCardsButtonClick,
            logAllCardsCloseButtonClick: logAllCardsCloseButtonClick,
        })
    ], InternalPlayer);
    return InternalPlayer;
}(React.Component));
export default InternalPlayer;
