import _ from 'lodash';
import { withWidgetProps } from './widget-props';
export var withPubSubEvents = withWidgetProps(function (_a) {
    var pubSubEvents = _a.pubSubEvents;
    return ({
        pubSubEvents: pubSubEvents,
    });
});
export var consumePubSubEvent = function (eventName, consumer, _a) {
    var prevProps = _a.prevProps, nextProps = _a.nextProps;
    if (!prevProps.pubSubEvents) {
        return;
    }
    if (_.isEqual(prevProps.pubSubEvents[eventName], nextProps.pubSubEvents[eventName])) {
        return;
    }
    if (nextProps.pubSubEvents[eventName]) {
        consumer(nextProps.pubSubEvents[eventName]);
    }
};
