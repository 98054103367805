import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import Icon from '../../../../../shared/components/icon/icon';
import WithTooltip from '../with-tooltip/with-tooltip';
import ChannelInfoButton from '../../../../components/channel-actions/channel-info-button';
import ChannelSubscriptionLabel from '../../../../../shared/components/channel-subscription-label/channel-subscription-label';
import AccountButton from '../../../../components/account/account-button/account-button';
import OpenShareOverlay from '../../../../containers/open-share-overlay/open-share-overlay';
import ShareButton from '../../../../components/share-button/share-button';
import { sendBiEvent } from '../../../../../shared/bi/send-bi-event';
import { CurrencySign } from '@wix/wix-vod-shared/dist/src/common/components/currency-sign';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './channel-actions.styl';
var ChannelActions = /** @class */ (function (_super) {
    __extends(ChannelActions, _super);
    function ChannelActions() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleSubscriptionClick = function () {
            _this.props.onSubscriptionRequest();
        };
        return _this;
    }
    ChannelActions.prototype.componentDidMount = function () {
        var canShowSubscriptionButton = this.props.canShowSubscriptionButton;
        if (canShowSubscriptionButton) {
            this.props.sendBiEvent('widget.subscription.displayed', {
                whereDisplayed: 'widget',
            });
        }
    };
    ChannelActions.prototype.wrapWithTooltip = function (content, tooltipMessage) {
        return (React.createElement(WithTooltip, { tooltip: tooltipMessage, className: styles.item, innerClassName: styles['tooltip-inner'] }, content));
    };
    ChannelActions.prototype.getShareButton = function () {
        var _this = this;
        var tooltipMessage = i18n.t('widget.compact-view.components.action-bar.share-tooltip-button');
        return (React.createElement(OpenShareOverlay, null, function (_a) {
            var toggleShare = _a.toggleShare, ariaLabel = _a.ariaLabel;
            return _this.wrapWithTooltip(React.createElement(ShareButton, { ariaLabel: ariaLabel, className: styles.button + " " + styles['share-button'], onClick: toggleShare }), tooltipMessage);
        }));
    };
    Object.defineProperty(ChannelActions.prototype, "channelInfoButton", {
        get: function () {
            var _a = this.props, channelData = _a.channelData, onInfoRequest = _a.onInfoRequest, canShowChannelInfo = _a.canShowChannelInfo;
            if (!canShowChannelInfo) {
                return null;
            }
            var tooltipMessage = i18n.t('widget.compact-view.components.action-bar.channel-info-tooltip');
            var content = (React.createElement(ChannelInfoButton, { className: styles.icon + " " + styles['icon-channel-info'], channelData: channelData, onClick: onInfoRequest }));
            return this.wrapWithTooltip(content, tooltipMessage);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelActions.prototype, "channelSubscriptionButton", {
        get: function () {
            var _a = this.props, channelData = _a.channelData, canShowSubscriptionButton = _a.canShowSubscriptionButton;
            if (!canShowSubscriptionButton) {
                return null;
            }
            var tooltipMessage = (React.createElement(ChannelSubscriptionLabel, { dealInfo: channelData.dealInfo }));
            var currency = channelData.dealInfo[0].currency;
            var content = (React.createElement("button", { className: styles.button, onClick: this.handleSubscriptionClick, "data-hook": "subscription-button", "aria-label": i18n.t('widget.accessibility.subscribe-to-channel') },
                React.createElement("div", { className: styles.icon + " " + styles['icon-paid'] },
                    React.createElement(CurrencySign, { currency: currency }))));
            return this.wrapWithTooltip(content, tooltipMessage);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelActions.prototype, "membershipActions", {
        get: function () {
            var _a = this.props, channelData = _a.channelData, onSubscriptionRequest = _a.onSubscriptionRequest, onAccountInfoAtOverlayRequest = _a.onAccountInfoAtOverlayRequest, canShowSignIn = _a.canShowSignIn, onLogInRequest = _a.onLogInRequest, onLogOutRequest = _a.onLogOutRequest;
            if (!canShowSignIn) {
                return null;
            }
            return (React.createElement(AccountButton, { className: classnames(styles.item, styles.icon), infoButtonClassName: styles['icon-account-info'], infoButtonTooltipClassName: styles['button-account-tooltip'], infoButtonPopoutTriangleClassName: styles['button-account-tooltip-triangle'], channelData: channelData, onClick: onAccountInfoAtOverlayRequest, onLoginClick: onLogInRequest, onLogoutClick: onLogOutRequest, onSubscribeClick: onSubscriptionRequest }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelActions.prototype, "closeButtonWithTooltip", {
        get: function () {
            var onCloseRequest = this.props.onCloseRequest;
            var tooltipMessage = i18n.t('widget.compact-view.components.action-bar.close');
            var content = (React.createElement("button", { className: styles.button, onClick: onCloseRequest, "data-hook": "close-action-bar", "aria-label": i18n.t('widget.accessibility.close-video-list') },
                React.createElement(Icon, { name: "exit", className: classnames(styles.icon, styles['icon-close']) })));
            return this.wrapWithTooltip(content, tooltipMessage);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelActions.prototype, "closeButtonWithLabel", {
        get: function () {
            var onCloseRequest = this.props.onCloseRequest;
            var label = i18n.t('widget.compact-view.components.action-bar.close');
            return (React.createElement("button", { className: classnames(styles.button, styles.item), onClick: onCloseRequest, "data-hook": "close-action-bar", tabIndex: "0" },
                React.createElement("span", { className: styles.icon },
                    React.createElement(Icon, { name: "exit", className: classnames(styles.icon, styles['icon-close'], styles['with-label']) }),
                    React.createElement("span", { className: styles.label }, label))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChannelActions.prototype, "closeButton", {
        get: function () {
            var canShowCloseButton = this.props.canShowCloseButton;
            if (!canShowCloseButton) {
                return null;
            }
            var _a = this, channelInfoButton = _a.channelInfoButton, channelSubscriptionButton = _a.channelSubscriptionButton, membershipActions = _a.membershipActions, closeButtonWithTooltip = _a.closeButtonWithTooltip, closeButtonWithLabel = _a.closeButtonWithLabel;
            var buttonsExceptClose = [
                this.getShareButton(),
                channelInfoButton,
                channelSubscriptionButton,
                membershipActions,
            ];
            return _.some(buttonsExceptClose)
                ? closeButtonWithTooltip
                : closeButtonWithLabel;
        },
        enumerable: true,
        configurable: true
    });
    ChannelActions.prototype.render = function () {
        return (React.createElement("nav", { className: styles.container },
            this.getShareButton(),
            this.channelInfoButton,
            this.channelSubscriptionButton,
            this.membershipActions,
            this.closeButton));
    };
    ChannelActions.propTypes = {
        channelData: PropTypes.object,
        videoItem: PropTypes.object,
        canShowCloseButton: PropTypes.bool,
        canShowChannelShare: PropTypes.bool,
        canShowChannelInfo: PropTypes.bool,
        canShowSignIn: PropTypes.bool,
        canShowSubscriptionButton: PropTypes.bool,
        onShareRequest: PropTypes.func,
        onInfoRequest: PropTypes.func,
        onAccountInfoAtOverlayRequest: PropTypes.func,
        onSubscriptionRequest: PropTypes.func,
        onLogInRequest: PropTypes.func,
        onLogOutRequest: PropTypes.func,
        onSubscriptionCancelRequest: PropTypes.func,
        onCloseRequest: PropTypes.func,
    };
    ChannelActions = __decorate([
        connect(null, { sendBiEvent: sendBiEvent }),
        withStyles(styles)
    ], ChannelActions);
    return ChannelActions;
}(React.Component));
export default ChannelActions;
