import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WindowSize from '../../../shared/containers/window-size';
import LocaleProvider from '../../../shared/containers/locale-provider';
import PaymentEventProxy from '../../../shared/components/payment-events/proxy';
import HomeView from '../../views/home/home';
import HomeWrapper from './wrapper/home.wrapper';
import { MobileModal } from '../../components/modal/modal';
import MobileSpinnerOverlay from '../../components/mobile-spinner-overlay/mobile-spinner-overlay';
import { isPlayingOptimistic } from '../../../shared/selectors/playback';
import { requestPause } from '../../../shared/redux/client/reducers/playback';
import BiHandler from '../../../shared/bi/bi-handler';
import ReloadChannelPaymentDataOnPayment from '../../../widget/data-components/reload-channel-payment-data-on-payment';
import ClearVideoUrlsCacheOnPayment from '../../../widget/data-components/clear-video-urls-cache-on-payment';
import PaymentsBiHandler from '../../../widget/components/payments/bi-handler';
import { getViewMode } from '../../../widget/selectors/view-mode';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from '../../../shared/styles/core.styl';
import EditorObserver from './editor-observer';
import WidgetHeightByContentSync from './widget-height-by-content-sync';
import { ViewerObserver } from '../../../shared/components/viewer-observer/viewer-observer';
import { AppSettingsObserver } from '../../../widget/data-components/app-settings-loader/app-settings-loader';
import { LoginWatcher } from '../../../widget/containers/login-watcher';
var HomeLayout = /** @class */ (function (_super) {
    __extends(HomeLayout, _super);
    function HomeLayout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pauseVideo = function () {
            var _a = _this.props, requestPause = _a.requestPause, selectedVideoId = _a.selectedVideoId;
            requestPause(selectedVideoId);
        };
        return _this;
    }
    HomeLayout.prototype.renderView = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(WidgetHeightByContentSync, null,
                React.createElement(HomeView, null)),
            React.createElement(BiHandler, null),
            React.createElement(PaymentsBiHandler, null),
            React.createElement(PaymentEventProxy, null),
            React.createElement(AppSettingsObserver, null),
            React.createElement(ReloadChannelPaymentDataOnPayment, null),
            React.createElement(ClearVideoUrlsCacheOnPayment, null),
            React.createElement(LoginWatcher, null),
            React.createElement(MobileModal, null),
            React.createElement(EditorObserver, null),
            React.createElement(ViewerObserver, { pauseVideo: this.pauseVideo, isVideoPlaying: this.props.isVideoPlaying })));
    };
    HomeLayout.prototype.renderSpinner = function () {
        return React.createElement(MobileSpinnerOverlay, null);
    };
    HomeLayout.prototype.render = function () {
        var _a = this.props, viewMode = _a.viewMode, windowSize = _a.windowSize;
        var shouldRenderView = windowSize.width;
        return (React.createElement(WindowSize, null, shouldRenderView ? (React.createElement(LocaleProvider, null,
            React.createElement(HomeWrapper, { viewMode: viewMode }, this.renderView()))) : (this.renderSpinner())));
    };
    HomeLayout.propTypes = {
        selectedVideoId: PropTypes.string,
        viewMode: PropTypes.string,
        windowSize: PropTypes.object.isRequired,
        isVideoPlaying: PropTypes.bool,
        requestPause: PropTypes.func,
    };
    HomeLayout = __decorate([
        connect(function (state) { return ({
            viewMode: getViewMode(state),
            selectedVideoId: state.selectedVideoId,
            windowSize: state.windowSize,
            isVideoPlaying: isPlayingOptimistic(state, state.selectedVideoId),
        }); }, {
            requestPause: requestPause,
        }),
        withStyles(styles)
    ], HomeLayout);
    return HomeLayout;
}(React.Component));
export default HomeLayout;
