import { getFullAccessActionName, FULL_ACCESS_ACTION_NAMES, } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
var getBuyClickHandler = function (_a) {
    var purchaseFn = _a.purchaseFn, subscribeFn = _a.subscribeFn, videoItem = _a.videoItem, channelData = _a.channelData;
    var status = getFullAccessActionName(channelData, videoItem);
    if (status === FULL_ACCESS_ACTION_NAMES.SUBSCRIBE) {
        return subscribeFn;
    }
    else if (status) {
        return purchaseFn;
    }
};
export default getBuyClickHandler;
