import { __read, __spread } from "tslib";
import _ from 'lodash';
import { createSelector } from 'reselect';
import { getThumbnailSpacing, getVideosInRowCount, getContainerMargins, getStretchToFullWidth, canShowVideoListItemContentBelow, } from '../../../shared/selectors/app-settings';
import { WIDTH_CONSTRAINTS } from '../../constants/thumbnail-sizes';
import { FULL_WIDTH_MARGINS, MAX_SPACING } from './constants';
var getWorkingAreaWidth = createSelector(function (state) { return state.windowSize.width; }, getContainerMargins, getStretchToFullWidth, function (width, containerMargins, isFullWidth) {
    var singleMargin = isFullWidth ? containerMargins : FULL_WIDTH_MARGINS;
    return width - 2 * singleMargin;
});
export var getMaxVideosCountInRow = createSelector(getWorkingAreaWidth, getThumbnailSpacing, getVideosInRowCount, function (width, spacing, inRowCount) {
    var range = _.range(1, inRowCount + 1);
    var fullyVisibleThumbnailsCount = _.find(range, function (itemsPerRow) {
        return itemsPerRow * WIDTH_CONSTRAINTS[0] + (itemsPerRow - 1) * spacing >
            width;
    }) - 1;
    return _.clamp(inRowCount, 1, fullyVisibleThumbnailsCount || inRowCount);
});
var roundToTwoDigits = function (value) { return Math.round(value * 100) / 100; };
export var getThumbnailWidth = createSelector(getWorkingAreaWidth, getThumbnailSpacing, getVideosInRowCount, getMaxVideosCountInRow, function (width, spacing, inRowCount, maxInRowCount) {
    var itemsInRowCount = Math.min(maxInRowCount, inRowCount);
    var spacingTotal = (itemsInRowCount - 1) * spacing;
    var availableWidth = maxInRowCount >= inRowCount || spacing ? width - spacingTotal : width;
    var widthCandidate = roundToTwoDigits(availableWidth / itemsInRowCount);
    return _.clamp.apply(_, __spread([widthCandidate], WIDTH_CONSTRAINTS));
});
var getForcedSpacing = createSelector(getThumbnailWidth, function (width) {
    /* eslint-disable curly */
    if (width > 699)
        return 80;
    if (width > 499)
        return 60;
    return 50;
});
export var getRowSpacing = createSelector(canShowVideoListItemContentBelow, getThumbnailSpacing, getForcedSpacing, function (shouldForceMinSpacing, spacing, forcedSpacing) {
    return shouldForceMinSpacing
        ? _.clamp(spacing, forcedSpacing, MAX_SPACING)
        : spacing;
});
