import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '../../../../../../shared/utils/withStyles';
import styles from './vertical.styl';
import { connect } from 'react-redux';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import CoverWithOverlay from '../../cover-with-overlay/cover-with-overlay';
import SlideMenu from '../../slide-menu/slide-menu';
import SlideMenuButton from '../../buttons/slide-menu-button';
import { loadMoreVideosForMobileVertical } from '../../../../../../widget/redux/client/lazy-channel-videos/actions';
import { showAutoPlay, getVerticalVisibleVideos, getShareModalTopPosition, } from '../../../../../../widget/selectors/layout';
import { isPlayingOptimistic, isPausedOptimistic, } from '../../../../../../shared/selectors/playback';
import ActionsStrip from '../../actions-strip/actions-strip';
import LoadMoreButton from '../../load-more-button/load-more-button';
import NoVideosOverlay from '../../no-videos-overlay/no-videos-overlay';
import AutoPlayVideo from '../../../../../../shared/components/autoplay-video/autoplay-video';
import { VERTICAL_LAYOUT_HEADER_HEIGHT, VERTICAL_LAYOUT_VIDEO_HEIGHT, VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM, VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT, } from '../../../../../../widget/constants/sizes';
var Vertical = /** @class */ (function (_super) {
    __extends(Vertical, _super);
    function Vertical(props) {
        var _this = _super.call(this, props) || this;
        _this.playVideo = function (videoItem) {
            var _a = _this.props, pauseVideo = _a.pauseVideo, playVideo = _a.playVideo, selectedVideoId = _a.selectedVideoId;
            if (selectedVideoId) {
                pauseVideo(selectedVideoId);
            }
            playVideo(videoItem);
            _this.setState({ isPlayerVisible: true });
        };
        _this.handleVideoClose = function (id) {
            _this.props.pauseVideo(id);
            _this.setState({ isPlayerVisible: false });
        };
        _this.handleLoadMoreClick = function () {
            _this.props.loadMoreVideosForMobileVertical();
        };
        _this.state = {
            PlaybackComponent: null,
            isPlayerVisible: true,
        };
        props
            .loadPlaybackModule()
            .then(function (PlaybackComponent) {
            _this.setState({ PlaybackComponent: PlaybackComponent });
        })
            .catch(_.noop);
        return _this;
    }
    Vertical.prototype.renderHeader = function () {
        var channelData = this.props.channelData;
        return (React.createElement("div", { className: styles.header, style: { height: VERTICAL_LAYOUT_HEADER_HEIGHT } },
            React.createElement("span", { className: styles.channelLabel }, channelData.title),
            React.createElement(SlideMenuButton, { className: styles.menuButton })));
    };
    Vertical.prototype.renderMenu = function () {
        var _a = this.props, isSlideMenuOpened = _a.isSlideMenuOpened, channelData = _a.channelData;
        if (!isSlideMenuOpened) {
            return null;
        }
        // isFirstVideo is used in compact layout`s "share popup"
        // to determine which modal need to be opened: "Share Video" or "Share Channel".
        // In Vertical layout we always show "Share Channel", so this property hardcoded
        return (React.createElement(SlideMenu, { showMenuButton: false, channelData: channelData, menuClassName: styles.menu, isFirstVideo: true }));
    };
    Vertical.prototype.renderVideos = function () {
        var _this = this;
        var _a = this.props, windowSize = _a.windowSize, showLogInForm = _a.showLogInForm, openMemberOnlyPage = _a.openMemberOnlyPage, onPlayRequestedDisallowed = _a.onPlayRequestedDisallowed, visibleVideos = _a.visibleVideos;
        return _.map(visibleVideos, function (videoItem, videoIndex) { return (React.createElement("div", { key: videoItem.id, "data-hook": "mobile-vertical-video-item", className: styles.videoItem, style: {
                height: VERTICAL_LAYOUT_VIDEO_HEIGHT,
                marginBottom: VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM,
            } },
            React.createElement("div", { className: styles.overlay },
                React.createElement(CoverWithOverlay, { videoItem: videoItem, itemWidth: windowSize.width, itemHeight: VERTICAL_LAYOUT_VIDEO_HEIGHT, isFirstVideo: false, onSignInRequested: showLogInForm, onPlayRequestedDisallowed: onPlayRequestedDisallowed, onPlayRequestedAllowed: memoizedPartial(_this.playVideo, videoItem), onMemberSignUp: memoizedPartial(openMemberOnlyPage, videoItem.id) })),
            _this.renderPlayer(videoIndex, videoItem))); });
    };
    Vertical.prototype.renderPlayer = function (videoIndex, videoItem) {
        var _a;
        var _b = this.props, channelData = _b.channelData, openMemberOnlyPage = _b.openMemberOnlyPage, _c = _b.playerSize, width = _c.width, height = _c.height, playback = _b.playback, selectedVideoId = _b.selectedVideoId;
        var _d = this.state, PlaybackComponent = _d.PlaybackComponent, isPlayerVisible = _d.isPlayerVisible;
        var sharePopupTopPositon = getShareModalTopPosition(videoIndex);
        if (!PlaybackComponent || !videoItem) {
            return null;
        }
        var isVideoPlaying = isPlayingOptimistic({ playback: playback }, videoItem.id);
        var isVideoPaused = isPausedOptimistic({ playback: playback }, videoItem.id);
        var isSelected = videoItem.id === selectedVideoId;
        return (React.createElement("div", { className: classnames(styles.player, (_a = {},
                _a[styles.visible] = isSelected && isPlayerVisible && (isVideoPlaying || isVideoPaused),
                _a)) },
            React.createElement(ActionsStrip, { videoItem: videoItem, sharePopupTopPositon: sharePopupTopPositon, channelData: channelData, className: styles.actionStrip, onCloseClick: memoizedPartial(this.handleVideoClose, videoItem.id) }),
            React.createElement(PlaybackComponent, { videoItem: videoItem, channelData: channelData, width: width, height: height, onMemberSignUp: openMemberOnlyPage })));
    };
    Vertical.prototype.renderLoadMoreButton = function () {
        var _a = this.props, visibleVideos = _a.visibleVideos, channelData = _a.channelData;
        return visibleVideos.length === channelData.videosCount ? null : (React.createElement(LoadMoreButton, { height: VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT, onClick: this.handleLoadMoreClick }));
    };
    Vertical.prototype.render = function () {
        var _a = this.props, channelData = _a.channelData, showAutoPlay = _a.showAutoPlay;
        if (!channelData.videosCount) {
            return React.createElement(NoVideosOverlay, { title: channelData.title });
        }
        return (React.createElement("div", { "data-hook": "mobile-vertical-layout", className: styles.layout },
            this.renderHeader(),
            this.renderMenu(),
            this.renderVideos(),
            this.renderLoadMoreButton(),
            showAutoPlay && React.createElement(AutoPlayVideo, { isMobile: true })));
    };
    Vertical.propTypes = {
        channelData: PropTypes.object.isRequired,
        windowSize: PropTypes.object.isRequired,
        playerSize: PropTypes.object.isRequired,
        mainVideo: PropTypes.object,
        visibleVideos: PropTypes.array.isRequired,
        selectedVideoId: PropTypes.string,
        isSlideMenuOpened: PropTypes.bool.isRequired,
        showAutoPlay: PropTypes.bool.isRequired,
        onSubscribeClick: PropTypes.func,
        showLogInForm: PropTypes.func.isRequired,
        openMemberOnlyPage: PropTypes.func.isRequired,
        onPlayRequestedDisallowed: PropTypes.func.isRequired,
        playVideo: PropTypes.func.isRequired,
        loadPlaybackModule: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        loadMoreVideosForMobileVertical: PropTypes.func.isRequired,
    };
    Vertical.defaultProps = {
        onSubscribeClick: _.noop,
    };
    Vertical = __decorate([
        connect(function (state) { return ({
            isSlideMenuOpened: state.slideMenuOpened,
            visibleVideos: getVerticalVisibleVideos(state),
            selectedVideoId: state.selectedVideoId,
            showAutoPlay: showAutoPlay(state),
            windowSize: state.windowSize,
            playback: state.playback,
        }); }, {
            loadMoreVideosForMobileVertical: loadMoreVideosForMobileVertical,
        }),
        withStyles(styles)
    ], Vertical);
    return Vertical;
}(React.Component));
export { Vertical };
