import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import CloseButton from '@wix/wix-vod-shared/dist/src/widget/ui-components/close-button/close-button';
import { canShareVideo } from '../../../../../shared/selectors/video-share';
import ShareMobile from '../../../../components/share-mobile/share-mobile';
import { LinkButton } from '../../../../../shared/components/button/button';
import ShareIcon from '../../../../assets/share.svg';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './actions-strip.styl';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
var ActionsStrip = /** @class */ (function (_super) {
    __extends(ActionsStrip, _super);
    function ActionsStrip() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActionsStrip.prototype.renderShareButton = function () {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem, sharePopupTopPositon = _a.sharePopupTopPositon;
        return (React.createElement(ShareMobile, { videoItem: videoItem, channelData: channelData, top: sharePopupTopPositon },
            React.createElement(LinkButton, { dataHook: "share-video-item-action", className: styles['share-icon'], ariaLabel: i18n.t('widget.accessibility.share') },
                React.createElement(ShareIcon, null))));
    };
    ActionsStrip.prototype.renderCloseButton = function () {
        var onCloseClick = this.props.onCloseClick;
        return (React.createElement(CloseButton, { onClick: onCloseClick, dataHook: "close-video-player-action", theme: "light", className: styles['close-icon'], ariaLabel: i18n.t('widget.compact-view.components.action-bar.close') }));
    };
    ActionsStrip.prototype.render = function () {
        var _a = this.props, className = _a.className, showVideoShare = _a.showVideoShare;
        return (React.createElement("div", { className: classnames(className, styles['actions-strip']), "data-hook": "player-actions-strip" },
            showVideoShare && this.renderShareButton(),
            showVideoShare && React.createElement("div", { className: styles.divider }, "|"),
            this.renderCloseButton()));
    };
    ActionsStrip.propTypes = {
        className: PropTypes.string,
        onCloseClick: PropTypes.func,
        videoItem: PropTypes.object,
        sharePopupTopPositon: PropTypes.number,
        showVideoShare: PropTypes.bool,
        channelData: PropTypes.object,
    };
    ActionsStrip = __decorate([
        connect(function (state) { return ({ showVideoShare: canShareVideo(state) }); }),
        withStyles(styles)
    ], ActionsStrip);
    return ActionsStrip;
}(React.Component));
export default ActionsStrip;
