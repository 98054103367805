import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { visibleInViewPort } from '../../../../utils/visible-on-screen';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './first-time-track-animation.styl';
var firstTimeTrackAnimation = function (WrappedComponent) {
    var FirstTimeTrackAnimation = /** @class */ (function (_super) {
        __extends(FirstTimeTrackAnimation, _super);
        function FirstTimeTrackAnimation(props) {
            var _this = _super.call(this, props) || this;
            _this.handleNextProps = function (props) {
                if (props === void 0) { props = _this.props; }
                var isVisibleInViewport = props.isVisibleInViewport;
                var _a = _this.state, isTrackStarted = _a.isTrackStarted, isShownAlready = _a.isShownAlready;
                if (__SERVER__) {
                    return;
                }
                if (isShownAlready) {
                    return;
                }
                var isShown = !isTrackStarted && isVisibleInViewport;
                _this.setState({
                    shouldApplyAnimationClassName: isShown,
                    isShownAlready: isShown,
                });
            };
            _this.updateTrackStarted = _.once(function () {
                _this.setState({
                    isTrackStarted: true,
                });
            });
            _this.state = {
                isTrackStarted: false,
                isShownAlready: false,
                shouldApplyAnimationClassName: false,
            };
            return _this;
        }
        FirstTimeTrackAnimation.prototype.componentDidMount = function () {
            this.handleNextProps();
        };
        FirstTimeTrackAnimation.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
            this.handleNextProps(nextProps);
        };
        FirstTimeTrackAnimation.prototype.render = function () {
            var _a;
            var trackClassName = this.props.trackClassName;
            var shouldApplyAnimationClassName = this.state.shouldApplyAnimationClassName;
            var mergedTrackClassName = classnames(trackClassName, (_a = {},
                _a[styles['start-animation']] = shouldApplyAnimationClassName,
                _a));
            return (React.createElement(WrappedComponent, __assign({}, this.props, { onUserStartedTracking: this.updateTrackStarted, trackClassName: mergedTrackClassName })));
        };
        FirstTimeTrackAnimation.propTypes = {
            isVisibleInViewport: PropTypes.bool,
            trackClassName: PropTypes.string,
        };
        FirstTimeTrackAnimation.defaultProps = {
            isVisibleInViewport: false,
        };
        FirstTimeTrackAnimation = __decorate([
            visibleInViewPort,
            withStyles(styles)
        ], FirstTimeTrackAnimation);
        return FirstTimeTrackAnimation;
    }(React.Component));
    FirstTimeTrackAnimation.WixWrappedComponent = WrappedComponent;
    return FirstTimeTrackAnimation;
};
export default firstTimeTrackAnimation;
