var _a;
import { __assign, __read, __spread } from "tslib";
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { STATUSES, CREATE_ORDER, SELECT_METHOD, INIT_CHECKOUT, setError, statusChange, } from '../actions/payment.constants';
var initialState = {
    config: null,
    error: null,
    orderId: null,
    paymentType: null,
    paymentMethods: null,
    selectedMethod: null,
    checkoutInitializationStatus: STATUSES.IDLE,
};
function handleError(state, _a) {
    var error = _a.payload;
    if (error && error.params) {
        error = error.params;
    }
    if (_.isError(error)) {
        error = error.message;
    }
    if (_.isObject(error)) {
        error = _.get(error, 'data.error_description');
    }
    if (!error || !_.isString(error)) {
        error = 'unknown_error';
    }
    return __assign(__assign({}, state), { status: 'ERROR', error: error });
}
function handleStatusChange(state, _a) {
    var status = _a.payload;
    return __assign(__assign({}, state), { status: status });
}
export default handleActions((_a = {},
    _a[INIT_CHECKOUT.START] = function (state) {
        return __assign(__assign({}, state), { checkoutInitializationStatus: STATUSES.PENDING });
    },
    _a[INIT_CHECKOUT.SUCCESS] = function (state) {
        return __assign(__assign({}, state), { checkoutInitializationStatus: STATUSES.DONE });
    },
    _a[INIT_CHECKOUT.FAIL] = function (state) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __assign(__assign(__assign({}, state), { checkoutInitializationStatus: STATUSES.FAIL }), handleError.apply(void 0, __spread([state], args)));
    },
    _a[CREATE_ORDER.START] = function (state, _a) {
        var payload = _a.payload;
        return __assign(__assign({}, state), { paymentType: _.get(payload, 'params.paymentType'), orderId: null, error: null, paymentMethods: null, selectedMethod: null });
    },
    _a[CREATE_ORDER.SUCCESS] = function (state, _a) {
        var payload = _a.payload;
        return __assign(__assign({}, state), { orderId: _.get(payload, 'params.orderId') });
    },
    _a[CREATE_ORDER.FAIL] = handleError,
    _a[setError] = handleError,
    _a[SELECT_METHOD] = function (state, _a) {
        var payload = _a.payload;
        return __assign(__assign({}, state), { error: null, selectedMethod: payload });
    },
    _a[statusChange] = handleStatusChange,
    _a), initialState);
