import { __decorate, __extends } from "tslib";
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { CurrencySign } from '@wix/wix-vod-shared/dist/src/common/components/currency-sign';
import { CircleButton } from '@wix/wix-vod-shared/dist/src/common/components/circle-button';
import { withStyles } from '../../../../../../shared/utils/withStyles';
import styles from '../overlay.styl';
var OverlayPaidAccessButton = /** @class */ (function (_super) {
    __extends(OverlayPaidAccessButton, _super);
    function OverlayPaidAccessButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function (event) {
            event.stopPropagation();
            event.preventDefault();
            _this.props.onClick();
        };
        return _this;
    }
    OverlayPaidAccessButton.prototype.render = function () {
        var _a;
        var _b = this.props, children = _b.children, isIconOnly = _b.isIconOnly, isFocusable = _b.isFocusable, className = _b.className, ariaHidden = _b.ariaHidden, currency = _b.currency;
        var classNames = classnames(styles.button, className, (_a = {},
            _a[styles['only-icon']] = isIconOnly,
            _a));
        return (React.createElement("a", { href: "#", className: classNames, onClick: this.handleClick, "data-hook": "paid-access-button", "aria-hidden": ariaHidden, tabIndex: isFocusable ? 0 : -1 },
            React.createElement("div", { className: styles.icon },
                React.createElement(CircleButton, { borderWidth: isIconOnly ? 3 : 2 },
                    React.createElement(CurrencySign, { currency: currency }))),
            children && React.createElement("span", { className: styles.text }, children)));
    };
    OverlayPaidAccessButton.propTypes = {
        onClick: PropTypes.func.isRequired,
        children: PropTypes.node,
        isFocusable: PropTypes.bool,
        isIconOnly: PropTypes.bool,
        className: PropTypes.string,
        iconClassName: PropTypes.string,
        currency: PropTypes.string,
        ariaHidden: PropTypes.bool,
    };
    OverlayPaidAccessButton.defaultProps = {
        isFocusable: true,
        isIconOnly: false,
        ariaHidden: false,
    };
    OverlayPaidAccessButton = __decorate([
        withStyles(styles)
    ], OverlayPaidAccessButton);
    return OverlayPaidAccessButton;
}(React.Component));
export default OverlayPaidAccessButton;
