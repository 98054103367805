import { __read, __spread } from "tslib";
import _ from 'lodash';
export var APP_STATUSES = {
    LOADING: 'LOADING',
    READY: 'READY',
};
var AppStatus = /** @class */ (function () {
    function AppStatus() {
        var _this = this;
        this.setLoaded = _.debounce(function () {
            _this.status = APP_STATUSES.READY;
            _this.setStatusAttribute();
        }, 300);
        this.pending = ['load'];
    }
    AppStatus.prototype.init = function () {
        var _this = this;
        this.root = document.getElementById('root');
        this.status = APP_STATUSES.LOADING;
        this.setStatusAttribute();
        window.addEventListener('load', function () { return _this.setMarkerReady('load'); }, {
            once: true,
        });
    };
    AppStatus.prototype.setStatusAttribute = function () {
        this.root.setAttribute('data-loading-status', this.status);
    };
    AppStatus.prototype.addMarker = function (marker) {
        this.pending = _.uniq(__spread(this.pending, [marker]));
    };
    AppStatus.prototype.setMarkerReady = function (marker) {
        this.pending = _.reject(this.pending, function (pendingMarker) { return marker === pendingMarker; });
        if (!this.pending.length) {
            this.setLoaded();
        }
    };
    return AppStatus;
}());
export var appStatus = new AppStatus();
