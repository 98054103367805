import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ClampText from '@wix/wix-vod-shared/dist/src/common/components/clamp-text/clamp-text';
import { withStyles } from '../../../../../../shared/utils/withStyles';
import styles from '../overlay.styl';
var OverlayTitle = /** @class */ (function (_super) {
    __extends(OverlayTitle, _super);
    function OverlayTitle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OverlayTitle.prototype.render = function () {
        var _a = this.props, children = _a.children, maxLinesCount = _a.maxLinesCount, className = _a.className, ariaHidden = _a.ariaHidden;
        if (!children) {
            return null;
        }
        return (React.createElement("div", { className: classnames(styles.title, className), "aria-hidden": ariaHidden },
            React.createElement(ClampText, { text: children, dataHook: "title", wrapperTag: "h3", clampOptions: { lineClamp: maxLinesCount } })));
    };
    OverlayTitle.propTypes = {
        className: PropTypes.string,
        children: PropTypes.string,
        maxLinesCount: PropTypes.number,
        ariaHidden: PropTypes.bool,
    };
    OverlayTitle.defaultProps = {
        maxLinesCount: 2,
        ariaHidden: false,
    };
    OverlayTitle = __decorate([
        withStyles(styles)
    ], OverlayTitle);
    return OverlayTitle;
}(React.Component));
export default OverlayTitle;
