import { __read, __spread } from "tslib";
import _ from 'lodash';
import { VIDEOS_ASPECT_RATIO } from '../../../constants/videos-aspect-ratio';
import { WIDTH_CONSTRAINTS } from '../../../constants/thumbnail-sizes';
export var getItemWidthForSliderHeight = function (sliderHeight, contentHeight) {
    var thumbnailHeight = sliderHeight - contentHeight;
    var itemWidth = Math.floor(thumbnailHeight * VIDEOS_ASPECT_RATIO);
    return _.clamp.apply(_, __spread([itemWidth], WIDTH_CONSTRAINTS));
};
export var getActionBarHeightForWidgetHeight = function (widgetHeight) {
    /* eslint-disable curly */
    if (widgetHeight < 500)
        return 31;
    return 36;
};
var SLIDER_PADDINGS = {
    275: 31,
    400: 36,
    700: 52,
};
export var getSliderHorizontalPadding = function (itemWidth, isArrowsOutside) {
    /* eslint-disable curly */
    if (!isArrowsOutside) {
        return 0;
    }
    if (itemWidth >= 700)
        return SLIDER_PADDINGS[700];
    if (itemWidth >= 400)
        return SLIDER_PADDINGS[400];
    return SLIDER_PADDINGS[275];
};
