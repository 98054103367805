import { __decorate, __extends } from "tslib";
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import SearchImage from './images/search.svg';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from './empty-search-results.styl';
var EmptySearchResults = /** @class */ (function (_super) {
    __extends(EmptySearchResults, _super);
    function EmptySearchResults() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EmptySearchResults.prototype.render = function () {
        var className = this.props.className;
        return (React.createElement("section", { className: classnames(styles['no-search-wrapper'], className) },
            React.createElement(SearchImage, { className: styles['no-search-icon'] }),
            React.createElement("span", { className: styles['no-search-text'] }, i18n.t('widget.home-view.no-search-results'))));
    };
    EmptySearchResults.propTypes = {
        className: PropTypes.string,
    };
    EmptySearchResults = __decorate([
        withStyles(styles)
    ], EmptySearchResults);
    return EmptySearchResults;
}(React.Component));
export default EmptySearchResults;
