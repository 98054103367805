import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { hasVideos } from '../../../../shared/utils/channel-helpers';
import { isSingleVideo } from '../../../../shared/selectors/app-settings';
import { sendBiEvent } from '../../../../shared/bi/send-bi-event';
import { RoundedIcon } from '../../../../shared/components/icon/icon';
import { handleLogOutPaymentModal } from '../../../../shared/utils/handle-logout-payment-modal';
import { getTitleText, getSubscribeButtonText, getPublisherText, getPlayButtonText, getPurchaseButtonText, getOpacityStyle, } from '../selectors';
import { openSubscription } from '../../../redux/client/player-overlay/actions/open-subscription';
import { openPlayerOverlay } from '../../../redux/client/player-overlay/actions/open-player-overlay';
import { openPurchase } from '../../../redux/client/player-overlay/actions/open-purchase';
import LiveLabel from '../../../containers/live-label/live-label';
import Title from '../partials/title';
import ComingSoon from '../partials/coming-soon';
import { withStyles } from '../../../../shared/utils/withStyles';
import styles from './strip.styl';
var OverlayStrip = /** @class */ (function (_super) {
    __extends(OverlayStrip, _super);
    function OverlayStrip() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePlaySelectedVideo = function (event) {
            var _a = _this.props, videoItem = _a.videoItem, onPlaySelectedVideo = _a.onPlaySelectedVideo;
            event.preventDefault();
            event.stopPropagation();
            onPlaySelectedVideo(videoItem);
        };
        _this.handlePurchaseButtonClick = function (event) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            var _a = _this.props, videoItem = _a.videoItem, openPlayerOverlay = _a.openPlayerOverlay;
            openPlayerOverlay(videoItem);
        };
        _this.handleSubscriptionButtonClick = function (event) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            _this.props.openSubscription();
        };
        return _this;
    }
    OverlayStrip.prototype.componentDidMount = function () {
        var subscribeButtonText = this.props.subscribeButtonText;
        if (subscribeButtonText) {
            this.props.sendBiEvent('widget.subscription.displayed', {
                whereDisplayed: 'cover',
            });
        }
        handleLogOutPaymentModal({
            onPurchase: this.handlePurchaseButtonClick,
            onSubscription: this.handleSubscriptionButtonClick,
        });
    };
    OverlayStrip.prototype.renderPublisher = function () {
        var styleName = classnames(styles.publisher, 'qa-widget-overlay-publisher');
        return (React.createElement("div", { className: styleName, "data-hook": "overlay-publisher" }, this.props.publisherText));
    };
    OverlayStrip.prototype.renderLiveLabel = function () {
        var videoItem = this.props.videoItem;
        return (React.createElement(LiveLabel, { itemType: videoItem.itemType, mediaInfo: videoItem.mediaInfo, videoTitle: videoItem.title, startTime: videoItem.dateStartLive, className: styles['live-label'] }));
    };
    OverlayStrip.prototype.renderPlayButton = function () {
        var _a = this.props, channelData = _a.channelData, isContentFocusable = _a.isContentFocusable, isSingleVideo = _a.isSingleVideo, playButtonText = _a.playButtonText, isPlayerModuleLoaded = _a.isPlayerModuleLoaded;
        if (!hasVideos(channelData) && !isSingleVideo) {
            return React.createElement(ComingSoon, { className: styles['soon-info'] });
        }
        if (!playButtonText || !isPlayerModuleLoaded) {
            return null;
        }
        var styleName = classnames(styles.button, styles['play-button']);
        var iconStyleName = classnames(styles.icon, styles['play-icon']);
        return (React.createElement("a", { href: "", className: styleName, "data-hook": "overlay-play-button", onClick: this.handlePlaySelectedVideo, tabIndex: isContentFocusable ? 0 : -1 },
            React.createElement(RoundedIcon, { className: iconStyleName, name: "arrow-right-filled" }),
            React.createElement("span", { className: styles.text }, playButtonText)));
    };
    OverlayStrip.prototype.renderPurchaseButton = function () {
        var purchaseButtonText = this.props.purchaseButtonText;
        if (!purchaseButtonText) {
            return null;
        }
        var styleName = classnames('qa-widget-overlay-buy-from-button', styles.button);
        return (React.createElement("a", { href: "", className: styleName, onClick: this.handlePurchaseButtonClick, "data-hook": "paid-access-button", tabIndex: this.props.isContentFocusable ? 0 : -1 },
            React.createElement("span", { className: styles.text }, purchaseButtonText)));
    };
    OverlayStrip.prototype.renderSubscribeButton = function () {
        var _a = this.props, subscribeButtonText = _a.subscribeButtonText, isContentFocusable = _a.isContentFocusable;
        if (!subscribeButtonText) {
            return null;
        }
        var styleName = classnames(styles.button, 'qa-widget-overlay-subscribe-button');
        return (React.createElement("a", { href: "", className: styleName, "data-hook": "subscribe-button", onClick: this.handleSubscriptionButtonClick, tabIndex: isContentFocusable ? 0 : -1 },
            React.createElement("span", { className: styles.text }, subscribeButtonText)));
    };
    OverlayStrip.prototype.renderButtons = function () {
        var buttonsWrapperClassName = this.props.buttonsWrapperClassName;
        var styleName = classnames(styles['buttons-wrapper'], buttonsWrapperClassName);
        return (React.createElement("div", { className: styleName },
            this.renderPlayButton(),
            this.renderSubscribeButton() || this.renderPurchaseButton()));
    };
    OverlayStrip.prototype.render = function () {
        var _a = this.props, channelData = _a.channelData, videoItem = _a.videoItem, className = _a.className, titleText = _a.titleText, publisherText = _a.publisherText, opacityStyle = _a.opacityStyle;
        if (!channelData) {
            return null;
        }
        var styleName = classnames(className, styles.overlay);
        return (React.createElement("div", { style: opacityStyle, className: styleName, "data-hook": "player-overlay player-overlay-visible", onClick: this.handlePlaySelectedVideo },
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles['top-content'] },
                    publisherText && this.renderPublisher(),
                    titleText && (React.createElement("div", { className: styles.title },
                        React.createElement(Title, { text: titleText }))),
                    videoItem && this.renderButtons(),
                    this.renderLiveLabel()))));
    };
    OverlayStrip.propTypes = {
        buttonsWrapperClassName: PropTypes.string,
        isSingleVideo: PropTypes.bool,
        currentSiteUser: PropTypes.object,
        videoItem: PropTypes.object,
        channelData: PropTypes.object,
        isContentFocusable: PropTypes.bool,
        className: PropTypes.string,
        titleText: PropTypes.node,
        publisherText: PropTypes.node,
        subscribeButtonText: PropTypes.node,
        playButtonText: PropTypes.node,
        purchaseButtonText: PropTypes.node,
        opacityStyle: PropTypes.object.isRequired,
        onPlaySelectedVideo: PropTypes.func,
        openSubscription: PropTypes.func.isRequired,
        openPurchase: PropTypes.func.isRequired,
        openPlayerOverlay: PropTypes.func.isRequired,
        isPlayerModuleLoaded: PropTypes.bool,
    };
    OverlayStrip.defaultProps = {
        isContentFocusable: true,
    };
    OverlayStrip = __decorate([
        connect(function (state, ownProps) { return ({
            isSingleVideo: isSingleVideo(state),
            titleText: getTitleText(state, ownProps),
            subscribeButtonText: getSubscribeButtonText(state, ownProps),
            publisherText: getPublisherText(state, ownProps),
            playButtonText: getPlayButtonText(state, ownProps),
            purchaseButtonText: getPurchaseButtonText(state, ownProps),
            opacityStyle: getOpacityStyle(state),
            isPlayerModuleLoaded: state.modules.player,
        }); }, {
            openSubscription: openSubscription,
            openPlayerOverlay: openPlayerOverlay,
            openPurchase: openPurchase,
            sendBiEvent: sendBiEvent,
        }),
        withStyles(styles)
    ], OverlayStrip);
    return OverlayStrip;
}(React.Component));
export default OverlayStrip;
