import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { focusSource, focusWithin, } from '@wix/wix-vod-shared/dist/src/common/accessibility/focus';
import { getAllSettings, getChannelLayout, } from '../../../shared/selectors/app-settings';
import ForceSliderHeight from '../../layouts/slider/containers/force-slider-height/force-slider-height';
import { isCurrentComponentIdInUrl, getVideoIdFromUrl, } from '../../selectors/query-params';
import { isVideoPlayingOptimistic } from '../../selectors/video-playback-status';
import { openFullScreenVideoOnMount } from '../../redux/client/actions/full-screen-video-on-mount';
import PaymentEventProxy from '../../../shared/components/payment-events/proxy';
import { pauseVideo } from '../../redux/client/actions/player/change-playback-status';
import TooltipPopout from '../../../shared/components/tooltip/tooltip-popout';
import Modal from '../../components/modal/modal';
import BiHandler from '../../../shared/bi/bi-handler';
import { PersistentEventsHandler } from '../../containers/persistent-events-handler/persistent-events-handler';
import { LayoutSpinner } from '../../layouts/components/spinner/spinner';
import LocaleProvider from '../../../shared/containers/locale-provider';
import PlayerModuleLoader from '../../data-components/player-module-loader';
import WindowSize from '../../../shared/containers/window-size';
import { EditorObserver } from '../../components/editor-observer/editor-observer';
import { ViewerObserver } from '../../../shared/components/viewer-observer/viewer-observer';
import { AppSettingsObserver } from '../../data-components/app-settings-loader/app-settings-loader';
import { LoginWatcher } from '../../containers/login-watcher';
import ReloadChannelPaymentDataOnPayment from '../../data-components/reload-channel-payment-data-on-payment';
import ClearVideoUrlsCacheOnPayment from '../../data-components/clear-video-urls-cache-on-payment';
import PaymentsBiHandler from '../../components/payments/bi-handler';
import PremiumIntentUpdater from '../../components/premium-intent-updater/premium-intent-updater';
import { fitIntoView } from '../../../shared/worker/actions/fit-into-view';
import { getViewMode, isSiteMode, isEditorMode, } from '../../selectors/view-mode';
import { withStyles } from '../../../shared/utils/withStyles';
import styles from '../../../shared/styles/core.styl';
import SEOStructuredData from '../seo/seo';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { getCompId } from '../../redux/client/hydrated-data/hydrated-data';
var DesktopView = /** @class */ (function (_super) {
    __extends(DesktopView, _super);
    function DesktopView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.saveContainerRef = function (ref) {
            focusSource.engage(ref);
            _this.containerRef = ref;
        };
        return _this;
    }
    DesktopView.prototype.componentDidMount = function () {
        // TODO: remove experiments fetching after opening video embed to all users
        this.focusWithinHandler = focusWithin();
        this.scrollToIfNecessary();
        this.props.openFullScreenVideoOnMount();
    };
    DesktopView.prototype.componentWillUnmount = function () {
        this.focusWithinHandler.disengage();
        focusSource.disengage(this.containerRef);
    };
    DesktopView.prototype.scrollToIfNecessary = function () {
        var _a = this.props, videoIdFromUrl = _a.videoIdFromUrl, isCompIdInUrl = _a.isCompIdInUrl, fitIntoView = _a.fitIntoView;
        if (isCompIdInUrl || videoIdFromUrl) {
            fitIntoView();
            this.containerRef.scrollIntoView({ behavior: 'smooth' });
        }
    };
    DesktopView.prototype.render = function () {
        var _a = this.props, children = _a.children, appSettings = _a.appSettings, viewMode = _a.viewMode, layout = _a.layout, windowSize = _a.windowSize, isVideoPlaying = _a.isVideoPlaying, pauseVideo = _a.pauseVideo, isSiteMode = _a.isSiteMode, isEditorMode = _a.isEditorMode, isV3 = _a.isV3, compId = _a.compId;
        return (React.createElement(WindowSize, null,
            React.createElement(SEOStructuredData, null),
            React.createElement("div", { style: isSiteMode ? { height: '100%' } : { height: windowSize.height }, ref: this.saveContainerRef, "data-layout": layout, "data-app-version": __CI_APP_VERSION__, className: styles.root, "data-view-mode": viewMode },
                React.createElement(BiHandler, null),
                React.createElement(PaymentEventProxy, null),
                React.createElement(PaymentsBiHandler, null),
                isSiteMode || windowSize.width ? (React.createElement(PlayerModuleLoader, null,
                    React.createElement(React.Fragment, null,
                        React.createElement(LocaleProvider, null,
                            React.createElement(ForceSliderHeight, null, children)),
                        React.createElement(PersistentEventsHandler, null)))) : (React.createElement(LayoutSpinner, null)),
                React.createElement(AppSettingsObserver, null),
                React.createElement(EditorObserver, null),
                React.createElement(ViewerObserver, { isVideoPlaying: isVideoPlaying, pauseVideo: pauseVideo }),
                React.createElement(ReloadChannelPaymentDataOnPayment, null),
                React.createElement(ClearVideoUrlsCacheOnPayment, null),
                React.createElement(LoginWatcher, null),
                React.createElement(Modal, { settings: appSettings }),
                React.createElement(TooltipPopout, { compId: compId }),
                isEditorMode && React.createElement(PremiumIntentUpdater, { isV3: isV3 }))));
    };
    DesktopView.propTypes = {
        windowSize: PropTypes.object.isRequired,
        children: PropTypes.node,
        videoIdFromUrl: PropTypes.string,
        appSettings: PropTypes.object,
        viewMode: PropTypes.string,
        isSiteMode: PropTypes.bool.isRequired,
        isEditorMode: PropTypes.bool.isRequired,
        layout: PropTypes.number,
        isCompIdInUrl: PropTypes.bool,
        singleVideoId: PropTypes.string,
        isVideoPlaying: PropTypes.bool,
        pauseVideo: PropTypes.func,
        fitIntoView: PropTypes.func.isRequired,
        isV3: PropTypes.bool.isRequired,
        compId: PropTypes.string,
    };
    DesktopView = __decorate([
        connect(function (state) { return ({
            appSettings: getAllSettings(state),
            videoIdFromUrl: getVideoIdFromUrl(state),
            viewMode: getViewMode(state),
            compId: getCompId(state),
            layout: getChannelLayout(state),
            isCompIdInUrl: isCurrentComponentIdInUrl(state),
            isVideoPlaying: isVideoPlayingOptimistic(state),
            windowSize: state.windowSize,
            isSiteMode: isSiteMode(state),
            isEditorMode: isEditorMode(state),
            isV3: isGraphAPIEnabled(),
        }); }, {
            pauseVideo: pauseVideo,
            fitIntoView: fitIntoView,
            openFullScreenVideoOnMount: openFullScreenVideoOnMount,
        }),
        withStyles(styles)
    ], DesktopView);
    return DesktopView;
}(React.Component));
export { DesktopView };
