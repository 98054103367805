import _ from 'lodash';
import { SEARCH_ACTIONS } from '../../client/actions/search';
import { SELECTED_VIDEO_ACTIONS } from '../../client/actions/select-video';
import { UPDATE_WINDOW_SIZE } from '../../client/actions/window-size';
import { APP_SETTINGS_UPDATE } from '../../client/actions/app-settings';
import { NAMES as CHANNEL_VIDEOS_LIST } from '../../../../shared/redux/server/actions/channel/videos/list-public';
import { NAMES as CHANGE_PLAYBACK_STATUS } from '../../client/actions/player/change-playback-status';
import { loadMore, loadMoreVideoPages, resetLazyChannelVideos, goToLazyVideoIndex, } from '../../client/lazy-channel-videos/actions';
import { isStripLayout, isCompactLayout, isGridLayout, isMainItemChannelCover, isSliderLayout, } from '../../../../shared/selectors/app-settings';
import { getSearchQuery, getSearchQueryField, } from '../../../../shared/selectors/search';
import { getVideoIds, getVideosPerPageCount, getCursor, } from '../../client/lazy-channel-videos/selectors';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
export default (function (_a) {
    var getState = _a.getState, dispatch = _a.dispatch;
    return function (next) { return function (action) {
        var prevState = getState();
        var returnValue = next(action); // eslint-disable-line callback-return
        var state = getState();
        var reloadLazyChannelVideos = function () {
            dispatch(resetLazyChannelVideos());
            dispatch(loadMoreVideoPages(2));
        };
        var setVideoIndexById = function (videoId) {
            var videoIndex = _.indexOf(getVideoIds(state), videoId);
            var cursor = getCursor(state);
            var isStrip = isStripLayout(state);
            var isCompact = isCompactLayout(state);
            var isLigthbox = state.isLightbox;
            if (videoIndex === -1 && cursor && (isStrip || isCompact || isLigthbox)) {
                dispatch(loadMoreVideoPages());
            }
            dispatch(goToLazyVideoIndex(videoIndex));
        };
        var loadMoreIfItemsPerPageChanged = function (_a) {
            var _b;
            var _c = (_a === void 0 ? {} : _a).exact, exact = _c === void 0 ? false : _c;
            var missingItemsCount = getVideosPerPageCount(state) -
                getVideoIds(state).length +
                (exact ? 0 : 1);
            var cursor = getCursor(state);
            var searchQuery = getSearchQuery(state);
            var searchQueryField = getSearchQueryField(state);
            if (missingItemsCount > 0 && cursor) {
                dispatch(loadMore(missingItemsCount, (_b = {}, _b[searchQueryField] = searchQuery, _b)));
            }
        };
        var updateMainVideoIndex = function () {
            if (isMainItemChannelCover(state) && !state.isLightbox) {
                return;
            }
            setVideoIndexById(getMainVideoId(state));
        };
        switch (action.type) {
            case SEARCH_ACTIONS.UPDATE_SEARCH:
            case SEARCH_ACTIONS.RESET_SEARCH:
                if (!_.isEqual(prevState.search, state.search) ||
                    _.size(getVideoIds(state)) === 0) {
                    reloadLazyChannelVideos();
                }
                break;
            case SELECTED_VIDEO_ACTIONS.SET:
            case CHANGE_PLAYBACK_STATUS.REQUEST_PLAY:
                setVideoIndexById(action.payload);
                break;
            case UPDATE_WINDOW_SIZE:
                loadMoreIfItemsPerPageChanged({
                    exact: isGridLayout(state) ||
                        isCompactLayout(state) ||
                        isStripLayout(state) ||
                        isSliderLayout(state),
                });
                break;
            case APP_SETTINGS_UPDATE:
                if (isGridLayout(state)) {
                    loadMoreIfItemsPerPageChanged({ exact: true });
                }
                break;
            case CHANNEL_VIDEOS_LIST.SUCCESS:
                updateMainVideoIndex();
                break;
            default:
                break;
        }
        return returnValue;
    }; };
});
