import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import Input from '@wix/wix-vod-shared/dist/src/widget/ui-components/input/input';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from './copy-content.styl';
var HIDE_MESSAGE_TIMEOUT = 3000;
var CopyContent = /** @class */ (function (_super) {
    __extends(CopyContent, _super);
    function CopyContent(props) {
        var _this = _super.call(this, props) || this;
        _this.handleCopyClick = function () {
            var showSuccessMessage = _this.state.showSuccessMessage;
            if (!showSuccessMessage) {
                _this.copyToClipboard();
            }
        };
        _this.copyToClipboard = function () {
            if (document.queryCommandSupported('copy')) {
                _this.input.select();
                document.execCommand('copy');
                _this.setState({ showSuccessMessage: true });
                _this.hideMessages();
                _this.props.onContentCopied();
            }
        };
        _this.setRef = function (node) {
            _this.input = node;
        };
        _this.state = {
            showSuccessMessage: false,
        };
        return _this;
    }
    CopyContent.prototype.componentDidMount = function () {
        this.props.onInit(this.input);
    };
    CopyContent.prototype.hideMessages = function () {
        var _this = this;
        setTimeout(function () {
            _this.setState({ showSuccessMessage: false });
        }, HIDE_MESSAGE_TIMEOUT);
    };
    CopyContent.prototype.render = function () {
        var _a;
        var _b = this.props, onFocus = _b.onFocus, value = _b.value, isRTL = _b.isRTL;
        var showSuccessMessage = this.state.showSuccessMessage;
        return (React.createElement("div", { className: styles.copy },
            React.createElement(Input, { dataHook: "iframe-code", className: styles['iframe-code'], setRef: this.setRef, onFocus: onFocus, value: value }),
            React.createElement("button", { className: classNames(styles['copy-button'], (_a = {},
                    _a[styles.success] = showSuccessMessage,
                    _a[styles.rtl] = isRTL,
                    _a[styles.ltr] = !isRTL,
                    _a)), tabIndex: "0", onClick: this.handleCopyClick },
                React.createElement("i", { className: styles.checkmark }),
                React.createElement("span", { className: styles.text }, i18n.t('share-overlay.embed.copy')))));
    };
    CopyContent.propTypes = {
        value: PropTypes.string,
        onInit: PropTypes.func,
        onFocus: PropTypes.func,
        onContentCopied: PropTypes.func,
        isRTL: PropTypes.bool,
    };
    CopyContent = __decorate([
        withStyles(styles)
    ], CopyContent);
    return CopyContent;
}(React.Component));
export default CopyContent;
