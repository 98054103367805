import { __decorate, __extends } from "tslib";
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../../../../shared/components/icon/icon';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { withStyles } from '../../../../../shared/utils/withStyles';
import styles from '../account-button.styl';
var AccountInfoButton = /** @class */ (function (_super) {
    __extends(AccountInfoButton, _super);
    function AccountInfoButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AccountInfoButton.prototype.render = function () {
        var _a = this.props, className = _a.className, onClick = _a.onClick, isFocusable = _a.isFocusable;
        return (React.createElement("button", { className: classnames(styles['account-info-button'], className), onClick: onClick, "data-hook": "channel-account-info", tabIndex: isFocusable ? 0 : -1, "aria-label": i18n.t('widget.accessibility.my-account') },
            React.createElement(Icon, { name: "avatar" })));
    };
    AccountInfoButton.propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        isFocusable: PropTypes.bool,
    };
    AccountInfoButton.defaultProps = {
        isFocusable: true,
    };
    AccountInfoButton = __decorate([
        withStyles(styles)
    ], AccountInfoButton);
    return AccountInfoButton;
}(React.Component));
export default AccountInfoButton;
